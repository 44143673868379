let model = ''
export function search_adline(searchCode, ttmsg, curtype = '', objSearch, seaechid, cip = '') {
    console.log('adline')
    console.log(searchCode)
    console.log(curtype)
    console.log(ttmsg)
    console.log(objSearch)
    console.log(seaechid)
    console.log(cip)
    // let juri = `http://search.kuwen10001.top/search2/?hid=${objSearch.hid}&word=${ttmsg}&word_id=${seaechid}&ip=${cip}&stemp=${new Date().getTime()}&model_=${model}&t_code=${curtype}`
    let juri = `http://search.kuwen10001.top/new_search?hid=${objSearch.hid}&word=${ttmsg}&word_id=${seaechid}&ip=${cip}&stemp=${new Date().getTime()}&model_=${model}&t_code=${curtype}`
    if (objSearch.oaid || objSearch.imei) {
        const ext = {
            ip: cip,
            ua: window.navigator.userAgent,
            imei: objSearch.imei,
            oaid: objSearch.oaid
        }
        juri += `&ext=${encodeURIComponent(JSON.stringify(ext))}`
        // juri += `&ext=${JSON.stringify(ext)}`
        console.log(ext)
    }
    console.log(juri)
    window.location.href = juri
}
let str = ''
export function getnewtitleci(e, hid = '') {
    return new Promise((resolve, reject) => {
        // console.log(window.location.pathname)
        let u = 'http://search.kuwen10001.top/new_word'
        // let u = 'http://search.kuwen10001.top/get_word5/'
        // let p = window.location.pathname
        // if (p.indexOf('andr_d') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word8/'
        // } else if (p.indexOf('bdss') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word9/'
        // } else if (p.indexOf('smss') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word10/'
        // } else if (p.indexOf('syss_') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word12/'
        // } else if (p.indexOf('syss1') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word13/'
        // } else if (p.indexOf('syss2') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word14/'
        // } else if (p.indexOf('syss3') !== -1) {
        //     u = 'http://search.kuwen10001.top/get_word15/'
        // }
        $.ajax({
            url: u,
            data: {
                page_size: e,
                hid,
                stemp: new Date().getTime(),
                // ip: returnCitySN.cip
            },
            method: "GET",
            dataType: 'json',
            async: false,
            success: function (res) {
                model = res.data.model_
                let mycsdtelist = []
                console.log(res)
                if (str === '') {
                    str = localStorage.getItem('shieldvalue') || ''
                    if (str === '') {
                        str = getsearchwalie()
                    }
                }
                const spamdata = str.split(' ')
                // res.data.word_list.forEach(item => {
                res.data.results.forEach(item => {
                    if (item.title && item.title.trim() && item.title != '#' && item.title.length > 1 && isNaN(Number(item.title)) && spamdata.indexOf(item.title) == '-1') {
                        // mycsdtelist.push(item.substring(0, 10))
                        item.title = item.title.replace(/['" “”,，\r\n]/g, "")
                        mycsdtelist.push(item)
                    }
                })
                resolve(mycsdtelist)
                // res.data.word_list.forEach(item => {
                //     // console.log(item)
                //     strdet += item.text
                // })
            },
            fail: function () {
                reject([])
            }
        })
    })
}

function getsearchwalie() {
    let str = ''
    $.ajax({
        url: "https://www.zixunkankan.xyz/api/getConfiguration?gid=16",
        dataType: 'json',
        async: false,
        success: function (res) {
            str = res.data.gcontent.replace(/(^\s*)|(\s*$)/g, "")
            localStorage.setItem('shieldvalue', str)
        }
    })
    return str
}

let cnssnum = 0
// 添加搜索词到页面上
export async function addThreePic4(item, i, type, contentTitleWeight, mycsdtelist, onexunhuan) {
    if (onexunhuan) {
        cnssnum = 0
    }
    // console.log(cnssnum)
    let ayyytitle = []
    // 原来的长词
    let oldLongterm = []
    // 处理过的10个以内显示的词
    let ayyytitlesamall = []
    // 词的id
    let ayyytitlesamallId = []
    const typetitlelist = ['cst', 'cdt', 'bst']
    const baidulist = ['cdt', 'bst', 'cda', 'bsa', 'cd', 'bs']
    ayyytitle = mycsdtelist.slice(cnssnum * 3, (cnssnum + 1) * 3)
    ayyytitle.forEach(item => {
        oldLongterm.push(item.title)
        ayyytitlesamall.push(item.title.substring(0, 10))
        ayyytitlesamallId.push(item.id)
    })
    cnssnum++
    let henDom = `<div style="font-weight:${contentTitleWeight}" class="oneArtic bottom-line">
    <div class="titleBix clickimg" data=${oldLongterm.join(',')} title-id=${ayyytitlesamallId.join(',')}>
        ${ayyytitlesamall.join(',')}
    </div>
    <div class="ingBox">
        <div style="position: relative;" class="${baidulist.indexOf(type) != '-1' ? 'bodernone' : ''}">
            <img src="" style="${baidulist.indexOf(type) == '-1' ? '' : 'display: none;'}" class="csstylewoc" data-src="https://static.zixunkankan.xyz/image628/csadiconne.png" alt="">
            <img src="" type="${type}" myindex='0' class='clickimg' data-src="${item.imgList[0]}" alt="">
        </div>
        <div class="${baidulist.indexOf(type) != '-1' ? 'bodernone' : ''}"><img src="" type="${type}" myindex='1' class='clickimg' data-src="${item.imgList[1]}" alt=""></div>
        <div class="${baidulist.indexOf(type) != '-1' ? 'bodernone' : ''}"><img src="" type="${type}" myindex='2' class='clickimg' data-src="${item.imgList[2]}" alt=""></div>
    </div>
    <div class="ggLaiyuan baiduicon">
        <text>${baidulist.indexOf(type) != '-1' ? '' : item.source}</text>
        <img src="" class="baiduimage" data-src="${baidulist.indexOf(type) != '-1' ? 'https://static.zixunkankan.xyz/image628/baiduicon.png' : ''}" alt="">
    </div>
    </div>
    `
    $("#snatuBox").append(henDom)

}

export function report_new(e) {
    $.ajax({
        // url: `http://192.168.1.109:9195/push_log2/?stemp=${new Date().getTime()}`,
        url: `http://search.kuwen10001.top/push_log2/?stemp=${new Date().getTime()}`,
        data: {
            word: e
        },
        method: "POST",
        dataType: 'json',
        async: false,
        success: function (res) { }
    })
}