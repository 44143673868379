const xiaomifunc = `(function () { var a = window.mi_ads = window.mi_ads || []; a.push({ el: document.currentScript.parentNode, args: { upId: "f657c711da5967dc104522e8d07a11ae", impRequests: { adsCount: 1, context: {} } }, cb: function (d) {/*请求回调，用于判断是否获取到广告:if (!d || !d.length) {console.log("没有获取到广告")} else {console.log("获取到广告")}*/ } }); function b() { window.__mi_sdk_init = true; while (a.length) { var d = a.shift(); MIUNIONADS.miAds(d.el, d.args, null, null, null, null, d.cb) } } var c = document.getElementById("mi-ad-sdk"); if (!c) { c = document.createElement("script"); c.setAttribute("type", "text/javascript"); c.setAttribute("src", "https://cnbj1.fds.api.xiaomi.com/prd-static/miui_ad_sdk_union/miads.min.js"); c.setAttribute("id", "mi-ad-sdk"); c.setAttribute("async", "async"); c.setAttribute("defer", "defer"); document.getElementsByTagName("head")[0].appendChild(c) } if (window.__mi_sdk_init) { b() } else { c.addEventListener("load", b) } })()`

function judgeBrand(sUserAgent) {
    // var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
    // var isXiaomi2 = sUserAgent.match('mi') == "mi";
    // var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
    // var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
    var isVivo = sUserAgent.match(/vivo/i) == "vivo";

    // if(isXiaomi || isXiaomi2|| isRedmi || isXiaomi2s){
    if (isVivo) {
        return 'xiaomi'
    } else {
        return 'default'
    }

}
// 获取随机数方法
export function getRandomNumber(n, m) {
    var random = Math.floor(Math.random() * (m - n + 1) + n);
    return random;
}
export function getxiaomiad() {
    // console.log(judgeBrand(navigator.userAgent.toLowerCase()))
    if (judgeBrand(navigator.userAgent.toLowerCase()) === 'xiaomi') {
        return
    }
    let wodeid = document.getElementById("snatuBox")
    let divs = document.createElement('div')
    divs.setAttribute("style", "padding: .106667rem .2rem .04rem;")
    let scp = document.createElement('script')
    scp.type = "text/javascript";
    scp.innerHTML = xiaomifunc
    divs.appendChild(scp)
    wodeid.appendChild(divs)
}
export function imgxiaomi(img) {
    return img.getAttribute('class') === 'img' || img.getAttribute('class') === 'ad-png'
}