console.log(111)
let host = window.location.host
let appid = '1111176562'
if (host === 'xiaokuohao.xyz') {
    appid = '1111246521'
}

let allrellow = true
// 优量汇广告
export function addYlhAd(youCode, ruleshow, yepage = 'home', popupAdid = '') {
    console.log(popupAdid)
    // let youCodecode = ''
    // if (youCode && youCode.indexOf(' ') == '-1') {
    //     youCodecode = youCode
    // } else {
    //     let youlist = youCode.split(' ')
    //     console.log(youlist)
    //     let youliangnum = localStorage.getItem('youliangnum') || 0
    //     youliangnum++
    //     localStorage.setItem('youliangnum', youliangnum)
    //     youCodecode = youlist[youliangnum % youlist.length]
    // }
    console.log('loading_优量汇广告_START')
    console.log(yepage)
    if (!youCode) {
        return
    }
    youCode = youCode?.trim()
    let youcodeList = youCode.split(' ')
    console.log(youcodeList)
    // console.log(youCode.split(' ')[0])
    // console.log(youCode.split(' ')[1])
    // console.log(youCode.split(' ')[2])
    // console.log(youCode.split(' ')[3])
    if (!allrellow) {
        try {
            if (youcodeList[0]) {
                window.TencentGDT.NATIVE.loadAd(youcodeList[0])
            }
            if (youcodeList[1]) {
                window.TencentGDT.NATIVE.loadAd(youcodeList[1])
            }
            if (youcodeList[2]) {
                window.TencentGDT.NATIVE.loadAd(youcodeList[2])
            }
            if (youcodeList[3]) {
                window.TencentGDT.NATIVE.loadAd(youcodeList[3])
            }
        } catch (error) {

        }
        // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[0])
        // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[1])
        // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[2])
        // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[3])
        return
    }
    window.TencentGDT = window.TencentGDT || [];
    // let ylhnum = $('.getadnumnum').length
    let ylhnum = 6
    // ruleshow.forEach(item => {
    //     if (item.substring(0, 1) === 'y') {
    //         ylhnum++
    //     }
    // })
    // if (Number(ylhnum) >= 10) {
    //     ylhnum = 10
    // }
    // ylhnum = 6
    for (let i = 0; i < youcodeList.length; i++) {
        console.log(i)
        window.TencentGDT.push({
            app_id: appid, // {String} - appid - 必填
            placement_id: youcodeList[i], // {String} - 广告位id - 必填
            type: 'native', // {String} - 原生广告类型 - 必填
            // muid_type: '1', // {String} - 移动终端标识类型，1：imei，2：idfa，3：mac号 - 选填
            // muid: '', // {String} - 加密终端标识，详细加密算法见API说明 -  选填
            count: ylhnum, // {Number} - 拉取广告的数量，默认是3，最高支持10 - 选填
            onComplete: function (res) {
                console.log('LOADING_RES_优量汇——————————-------')
                console.log(res)
                console.log(yepage + 'yepage')
                let ylhlist = []
                try {
                    $(`.y${i ? i : ''}lihclass`).each((index, item) => {
                        ylhlist.push($(item).attr('id'))
                        $(item).removeClass(`y${i ? i : ''}lihclass`);
                        $(item).addClass(`${$(item).attr('id')}+11`);
                    })
                } catch (error) {
                    console.log(error)
                }
                if (res && res.constructor === Array) {
                    // 原生模板广告位调用 window.TencentGDT.NATIVE.renderAd(res[0], 'containerId') 进行模板广告的渲染
                    // res[0] 代表取广告数组第一个数据
                    // containerId：广告容器ID
                    // res.forEach((item, index) => {
                    //     window.TencentGDT.NATIVE.renderAd(item, ylhlist[index])
                    // })
                    try {
                        window.TencentGDT.NATIVE.renderAd(res[0], `popupylhcode`)
                    } catch (error) {
                    }
                    try {
                        for (let k = 0; k < ylhlist.length; k++) {
                            if (res[k]) {
                                window.TencentGDT.NATIVE.renderAd(res[k], ylhlist[k])
                            } else {
                                window.TencentGDT.NATIVE.renderAd(res[res.length - 1], ylhlist[k])
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                    if (yepage === 'detail') {
                        try {
                            window.TencentGDT.NATIVE.renderAd(res[0], `divonead0${i + 1}`)
                        } catch (error) {

                        }
                        try {
                            window.TencentGDT.NATIVE.renderAd(res[0], `divonead5${i + 1}`)
                        } catch (error) {

                        }
                        try {
                            window.TencentGDT.NATIVE.renderAd(res[0], `divonead1${i + 1}`)
                        } catch (error) {

                        }
                        try {
                            window.TencentGDT.NATIVE.renderAd(res[0], `divonead2${i + 1}`)
                        } catch (error) {

                        }
                        try {
                            window.TencentGDT.NATIVE.renderAd(res[0], `divonead3${i + 1}`)
                        } catch (error) {

                        }
                        try {
                            window.TencentGDT.NATIVE.renderAd(res[0], `divonead4${i + 1}`)
                        } catch (error) {

                        }
                    }
                    // if (yepage === 'detailmore') {
                    //     console.log('morrr')
                    //     try {
                    //         window.TencentGDT.NATIVE.renderAd(res[0], `divonead1${i+1}`)
                    //     } catch (error) {

                    //     }
                    //     try {
                    //         window.TencentGDT.NATIVE.renderAd(res[0], `divonead2${i+1}`)
                    //     } catch (error) {

                    //     }
                    //     try {
                    //         window.TencentGDT.NATIVE.renderAd(res[0], `divonead3${i+1}`)
                    //     } catch (error) {

                    //     }
                    //     try {
                    //         window.TencentGDT.NATIVE.renderAd(res[0], `divonead4${i+1}`)
                    //     } catch (error) {

                    //     }
                    // }
                } else {
                    // 加载广告API，如广告回调无广告，可使用loadAd再次拉取广告
                    // 注意：拉取广告频率每分钟不要超过20次，否则会被广告接口过滤，影响广告位填充率
                    setTimeout(function () {
                        console.log('SETTIME_OUT')
                        if (youcodeList[0]) {
                            window.TencentGDT.NATIVE.loadAd(youcodeList[0])
                        }
                        if (youcodeList[1]) {
                            window.TencentGDT.NATIVE.loadAd(youcodeList[1])
                        }
                        if (youcodeList[2]) {
                            window.TencentGDT.NATIVE.loadAd(youcodeList[2])
                        }
                        if (youcodeList[3]) {
                            window.TencentGDT.NATIVE.loadAd(youcodeList[3])
                        }
                    }, 3000)
                }
            }
        });
    };
    if (popupAdid.length > 0 && popupAdid.length < 20) {
        // 弹窗广告
        console.log('LOADING————————————弹窗广告优量汇——————')
        window.TencentGDT.push({
            app_id: appid, // {String} - appid - 必填
            placement_id: popupAdid, // {String} - 广告位id - 必填
            type: 'native', // {String} - 原生广告类型 - 必填
            muid_type: '1', // {String} - 移动终端标识类型，1：imei，2：idfa，3：mac号 - 选填
            muid: '', // {String} - 加密终端标识，详细加密算法见API说明 -  选填
            count: 1, // {Number} - 拉取广告的数量，默认是3，最高支持10 - 选填
            onComplete: function (res) {
                if (res && res.constructor === Array) {
                    try {
                        window.TencentGDT.NATIVE.renderAd(res[0], `popupylhcode`)
                    } catch (error) {
                    }
                }
            }
        });
    }
    console.log('loading_优量汇广告_END');
    (function () {
        var doc = document,
            h = doc.getElementsByTagName('head')[0],
            s = doc.createElement('script');
        s.async = true;
        // s.src = '//qzs.qq.com/qzone/biz/res/i.js';
        s.src = '//qzs.gdtimg.com/union/res/union_sdk/page/h5_sdk/i.js';
        h && h.insertBefore(s, h.firstChild);
    })();
    allrellow = false
    console.log("loading_FALSE")
}

// export {
//     appid
// }