import {
  getxiaomiad,
  imgxiaomi,
  getRandomNumber,
} from "./components/public.js";
// import { gethailiangad, gethailiangdom } from './components/hailiang.js'
import "./components/index.css";
import { addYlhAd } from "./components/ylh.js";
import {
  search_adline,
  addThreePic4,
  getnewtitleci,
} from "./components/search.js";
import { reportIp } from "./components/utils.js";
let onceMethod = false;
let stabilization = false;
let stabilizationTimer = null;
let stabilization_report = false;
let stabilizationTimer_report = null;
let currentIp = "";
// 弹窗广告次数
let popupadNum = 0;
let popupAdid = "";
try {
  currentIp = returnCitySN.cip;
} catch (error) {}

function DoOne(key, method) {
  var v = getCookie(key);
  if (!v) {
    method("do something");
    //获取第二天凌晨到当前时间的秒数
    var tim_sec =
      24 * 60 * 60 -
      (new Date().getHours() * 60 * 60 +
        new Date().getMinutes() * 60 +
        new Date().getSeconds());
    setCookie(key, "1", tim_sec);
  }
}

function dosome(msg) {
  console.log(msg);
  localStorage.clear();
}
// //写cookies
function setCookie(name, value, second) {
  if (!second) {
    second = 1 * 24 * 60 * 60;
  }
  var exp = new Date();
  exp.setTime(exp.getTime() + second * 1000);
  var coo =
    name + "=" + encodeURI(value) + ";expires=" + exp.toGMTString() + ";path=/";
  document.cookie = coo;
}
//读取cookies
function getCookie(name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(name + "="); //获取字符串的起点
    if (c_start != -1) {
      c_start = c_start + name.length + 1; //获取值的起点
      let c_end = document.cookie.indexOf(";", c_start); //获取结尾处
      if (c_end == -1) c_end = document.cookie.length; //如果是最后一个，结尾就是cookie字符串的结尾
      return decodeURI(document.cookie.substring(c_start, c_end)); //截取字符串返回
    }
  }
  return "";
}
try {
  DoOne("wodeCoookie", dosome);
} catch (error) {
  console.log(error);
}

// 第二部分
try {
  test.clearnHistory();
} catch (error) {}

function base64_decode(input) {
  let _utf8_decode = function (utftext) {
    var string = "";
    var i = 0;
    var c = 0,
      c1 = 0,
      c2 = 0,
      c3 = 0;
    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }
    return string;
  };
  const _keyStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  var output = "";
  var chr1, chr2, chr3;
  var enc1, enc2, enc3, enc4;
  var i = 0;
  input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
  while (i < input.length) {
    enc1 = _keyStr.indexOf(input.charAt(i++));
    enc2 = _keyStr.indexOf(input.charAt(i++));
    enc3 = _keyStr.indexOf(input.charAt(i++));
    enc4 = _keyStr.indexOf(input.charAt(i++));
    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;
    output = output + String.fromCharCode(chr1);
    if (enc3 != 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 != 64) {
      output = output + String.fromCharCode(chr3);
    }
  }
  output = _utf8_decode(output);
  return output;
}

// if (window.location.href.indexOf("&woshua=1") == "-1") {
//     window.location.replace(window.location.href + "&woshua=1");
// }
// if(returnCitySN.cip == '117.186.71.62'){
//     window.location.replace('http://kuwen10002.xyz/static/web/isdockingtuia.html?hid=322')
// }
// 截取url后面参数
const searchurl = window.location.search;
console.log(searchurl);
let objSearch = {
  tfinsh: "",
  taskmoney: "",
  tasktype: "",
  hid: "339",
  cp: "",
  tasktype: "8",
  taskClickTime: "3",
  taskTime: "60",
  loadStart: "",
  initStart: "",
  pid: "70001",
  md: "",
  xzp: "0",
  userid: "",
  taskid: "",
  imei: "",
  androidid: "",
  mac: "",
  oaid: "",
};
let myyyy = searchurl.substring(1, searchurl.length);
let nerList = myyyy.split("&");
for (var i = 0; i < nerList.length; i++) {
  if (nerList[i].split("=")[0] == "currentCity") {
    objSearch[nerList[i].split("=")[0]] = decodeURIComponent(
      nerList[i].split("=")[1]
    );
  } else {
    objSearch[nerList[i].split("=")[0]] = nerList[i].split("=")[1];
  }
}
// if (returnCitySN.cip == '117.186.71.62') {
//     // var vConsole = new VConsole();
//     try {
//         opprequiceapp(0, "", "")
//     } catch (error) {

//     }
//     // alert(11)
// }
// 跳转到百度小程序
function jumpbaiduapp(bgi = "pages/home/index") {
  try {
    let path = "pages/home/index";
    if (bgi === "game") {
      path = "pages/holddolls/index";
    }
    // const bgi = 'pages/home/index'
    // const bgi = 'pages/holddolls/index'
    const baiduuu = `baiduboxapp://swan/9pGw6n4kPLNnTInk9u90SoW0WmwGyocu/${path}?fromh=1`;
    // window.open(baiduuu)
    let fram = document.createElement("iframe");
    fram.setAttribute("src", baiduuu);
    document.getElementById("iframbox").appendChild(fram);
  } catch (error) {}
  // try {
  //     window.swanInvoke({
  //         appKey: '9pGw6n4kPLNnTInk9u90SoW0WmwGyocu',
  //         path: 'pages/home/index',
  //         query: {
  //             fromh: '1'
  //         }
  //     });
  // } catch (error) {

  // }
}
// 打开快应用
function opprequiceapp(
  min = 0,
  currttaskid,
  nountype,
  packagename = "com.constellationhome.pipi"
) {
  try {
    channelReady(function (bAvailable) {
      // alert('是否存在框架服务：' + bAvailable)
    });
  } catch (error) {
    console.log(error);
  }
  console.log({
    ic: "",
    id: "",
    mainid: min,
    currttaskid: currttaskid,
    nountype: nountype,
  });
  let apppath = "pages/main";
  if (packagename == "com.guesong.pipi") {
    const juuu = {
      0: "components/bookshelf",
      1: "components/kankan",
      2: "components/library",
      3: "components/me",
    };
    apppath = juuu[min];
    try {
      appRouter(`${packagename}`, `${apppath}`, {
        currttaskid: currttaskid,
        nountype: nountype,
      });
    } catch (error) {
      console.log(error);
    }
    console.log(29933);
    try {
      const jui = `hap://app/${packagename}/${apppath}?currttaskid=${currttaskid}&nountype=${nountype}`;
      console.log(jui);
      let fram = document.createElement("iframe");
      fram.setAttribute("src", jui);
      document.getElementById("iframbox").appendChild(fram);
      // window.location.href = jui
    } catch (error) {
      console.log(error);
    }
    return;
  }
  if (packagename == "com.plantree.pipi") {
    try {
      appRouter(`${packagename}`, `${apppath}`, {
        ic: "",
        id: "",
        pageid: min,
        currttaskid: currttaskid,
        nountype: nountype,
      });
    } catch (error) {
      console.log(error);
    }
    try {
      const jui = `hap://app/${packagename}/${apppath}?pageid=${min}&currttaskid=${currttaskid}&nountype=${nountype}`;
      console.log(jui);
      let fram = document.createElement("iframe");
      fram.setAttribute("src", jui);
      document.getElementById("iframbox").appendChild(fram);
      // window.location.href = jui
    } catch (error) {
      console.log(error);
    }
    return;
  }
  if (packagename == "baidu") {
    jumpbaiduapp(min);
    return;
  }

  try {
    appRouter(`${packagename}`, `${apppath}`, {
      ic: "",
      id: "",
      mainid: min,
      currttaskid: currttaskid,
      nountype: nountype,
    });
  } catch (error) {
    console.log(error);
  }
  try {
    const jui = `hap://app/${packagename}/${apppath}?mainid=${min}&currttaskid=${currttaskid}&nountype=${nountype}`;
    console.log(jui);
    let fram = document.createElement("iframe");
    fram.setAttribute("src", jui);
    document.getElementById("iframbox").appendChild(fram);
    // window.location.href = jui
  } catch (error) {
    console.log(error);
  }
}

// 针对映客
let taskClickTime = "";
let taskTime = "";
if (objSearch.md == "yk") {
  objSearch.nowtime = objSearch.loadStart;
  taskClickTime = objSearch.taskClickTime;
  taskTime = objSearch.taskTime;

  function jiancetaskover() {
    try {
      // let textboolear = '1';
      let textboolear = test.getTaskStatue();
      if (textboolear) {
        // overRenwu()
        return true;
      } else {
        return false;
      }
      // test.setBottomTime(0, "ssssss", 20, 1);
      // test.setTopAd(0, `http://51gongdu.com/static/web/settopad.html`);
    } catch (error) {
      return false;
    }
  }
  let abibter = setInterval(() => {
    if (jiancetaskover()) {
      clearInterval(abibter);
      // if (returnCitySN.cip == '117.186.71.62') {
      //     alert('true')
      //     return
      // }
      $.ajax({
        url: "https://www.zixunkankan.xyz/new/userCompleteNew",
        method: "GET",
        data: {
          userid: objSearch.userid,
          pid: objSearch.pid,
          taskid: objSearch.taskid,
          imei: objSearch.pid == "70001" ? "null" : objSearch.imei,
          nowtime: new Date().getTime(),
        },
        dataType: "json",
        success: function (res) {},
      });
    }
  }, 100);
}

//映客结束
// let cp = base64_decode(objSearch.cp)
const baseuritest = "http://192.168.1.119:7878";
const baseuri = "http://116.62.135.107:8000";
// const baseuri = "http://kuwen10004.xyz:8000"
let cp = base64_decode(objSearch.cp);
cp = cp.substring(0, cp.indexOf("tfinsh=") + 8);
let cps = cp.split("&");
for (var i = 0; i < cps.length; i++) {
  objSearch[cps[i].split("=")[0]] = cps[i].split("=")[1];
}
if (objSearch.hid == "343") {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?8d408dc6aa1314c2ed347609da37bf34";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
if (objSearch.hid == "378") {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?5d7cb62dbec1d8fb48ea641ac8ae121e";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
if (objSearch.hid == "373") {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?b1d86383aa9200d095f342e3690d7205";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
if (objSearch.hid == "380") {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?d41d7c919bf4443640c2dc5062ce2396";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
if (objSearch.hid == "347") {
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?dd6daa96445787b27b3299d5050050b8";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
  document.write(
    '<script smua="d=m&s=b&w=h5104dc4e982" src="https://h5-js-pro.treesmobsdk.com/webview.min.js?t=' +
      new Date().getTime() +
      '"></script>'
  );
}

try {
  // if (objSearch.pid == '10116' && objSearch.userid == '15957485') {
  //     window.location.href = 'http://kuwen10002.xyz/static/web/test/installkwz/index.html?imei=&pid=10115&userid=1313141415&gtype=1&meid=&androidid=eb5dffdb63732a57&oaid=&sign=e68021f1b43ecdea80af334b42315f0c'
  // }
  if (returnCitySN.cip == "117.186.71.62") {
    // window.location.href = 'http://kuwen10002.xyz/static/web/test/installkwz/index.html?imei=&pid=10115&userid=1313141415&gtype=1&meid=&androidid=eb5dffdb63732a57&oaid=&sign=e68021f1b43ecdea80af334b42315f0c'
    // window.location.href = 'https://pipinews.oss-cn-shanghai.aliyuncs.com/apppackage/kwzprod.apk'
  }
} catch (error) {}
console.log(objSearch);
// 添加搜狗广告
if (location.protocol == "http:") {
  var doc = document,
    h = doc.getElementsByTagName("head")[0],
    s = doc.createElement("script"),
    b = doc.createElement("script"),
    d = doc.createElement("script");
  s.async = true;
  s.src = "http://src.inte.sogoucdn.com/wap/js/aw.js";
  h && h.insertBefore(s, h.firstChild);
  b.async = true;
  b.defer = "defer";
  b.src = "//cpro.baidustatic.com/cpro/ui/cm.js";
  h && h.insertBefore(b, h.firstChild);
  d.async = true;
  d.defer = "defer";
  d.smua = "d=m&s=b&w=h5104dc4e982";
  d.src = `https://h5-js-pro.treesmobsdk.com/webview.min.js?t=${new Date().getTime()}'`;
  // h && h.insertBefore(d, h.firstChild);
  // 添加百度的广告js文件
}
let cunshuo =
  localStorage.getItem(
    "cunchuodetail" +
      objSearch.pid +
      objSearch.taskid +
      objSearch.hid +
      objSearch.userid
  ) || "";
let cunshuo1 =
  localStorage.getItem(
    "uuid" + objSearch.pid + objSearch.taskid + objSearch.hid
  ) || "";
if (cunshuo1 != objSearch.userid) {
  localStorage.clear();
  localStorage.setItem(
    "uuid" + objSearch.pid + objSearch.taskid + objSearch.hid,
    objSearch.userid
  );
}
if (cunshuo != objSearch.nowtime) {
  localStorage.removeItem(
    "jishiover" + objSearch.pid + objSearch.taskid + objSearch.hid
  );
  localStorage.setItem(
    "cunchuodetail" +
      objSearch.pid +
      objSearch.taskid +
      objSearch.hid +
      objSearch.userid,
    objSearch.nowtime
  );
  try {
    system.postMessage("firstcoming");
  } catch (error) {}
} else {
  try {
    system.postMessage("clickover");
  } catch (error) {}
}

if (
  objSearch.tasktype == "8" &&
  cunshuo != objSearch.nowtime &&
  objSearch.md == "yk"
) {
  if (objSearch.tfinsh != "1") {
    try {
      let erc = `点击x/${taskClickTime}篇文章并阅读fs即可获得奖励`;
      if (taskClickTime == "0") {
        erc = "阅读fs即可获得奖励";
      }
      // if (appdownload) {
      //     erc = appdownload.replace("c", taskClickTime);
      // }
      test.setBottomTime(1, erc, Number(taskTime), Number(taskClickTime));
      jumpe(e);
      // test.setTopAd(1, `http://51gongdu.com/static/web/settopad.html`);
    } catch (error) {
      console.log(error);
      // alert(error);
    }
  } else {
    try {
      test.setBottomTime(1, `任务已完成，请您明日再来吧~`, "0", "0");
      jumpe(e);
    } catch (error) {
      console.log(error);
    }
  }
}
if (
  objSearch.tasktype == 19 &&
  cunshuo != objSearch.nowtime &&
  objSearch.md == "yk"
) {
  if (objSearch.tfinsh != "1") {
    try {
      // let erc = `点击x/${taskClickTime}篇文章并阅读fs即可获得奖励`;
      let erc = `点击x/${taskClickTime}篇文章并每篇阅读10s即可获得奖励~`;
      if (taskClickTime == "0") {
        erc = "阅读fs即可获得奖励~";
      }
      // if (appdownload) {
      //     erc = appdownload.replace("c", taskClickTime);
      // }
      test.setBottomTime2(1, erc, 60, Number(taskTime), Number(taskClickTime));
      jumpe(e);
    } catch (error) {
      // alert(error);
    }
  } else {
    try {
      test.setBottomTime2(
        1,
        `任务已完成，请您明日再来吧~`,
        "0",
        10,
        "0"
        // Number(taskTime),
        // Number(taskClickTime)
      );
      jumpe(e);
    } catch (error) {}
  }
}
// 第三部分
let isdelicious = false;
if (
  window.location.host == "gmi1001.com" ||
  window.location.host == "gmi1001.com" ||
  window.location.host == "xiaokuohao.top" ||
  window.location.host == "www.xiaokuohao.top"
) {
  isdelicious = true;
}
// 列表页是否计时 true 计时 false 不及时
let listtimekeeper = false;
let onexunhuan = true;
// 搜索需要的参数
let searchCode = "";
let searchRandom = "";
let pageSize = getRandomNumber(1, 10);
let tengcnetCode = "";
let sogocode = "";
let tbsSCode = "";
let AdShowCity = "";
let tbsVCode = "";
let youCode = "";
let tbsRedShowCity = "";
// 搜索的页码配置
let tbsRed = "";
let tbsRedShiedCity = "";
let baiduCode = "";
// 互动的跳转链接
let hudongurl = "";
let ruleshow = [];
// 弹窗广告是否展示
let sigleAd = "";
// 是否可以跳转详情页
let allowJumpDetail = "";
// 右下角优化是否展示
let floatingyou = "";
let floatingyouUrl = "";
let numrr = 0;
// 直投广告跳转的url
let jumpzhitou = "";
let zhitouid = "";
let zhitouTypecode = "";
// 是否展示头部
let isShowHead = "";
let tid = 0;
let contentTitleWeight = "";
// 不能循环禁止下拉加载
let xianlajiazai = "1";
// 返回功能的url
let backshowUrl = "";
// 主题色
let bodyColor = "";
// 倒计时啊，我好烦啊
let bottomTime = "";
let currentclicknum = localStorage.getItem(
  "currentclicknum" + objSearch.pid + objSearch.taskid + objSearch.hid
)
  ? localStorage.getItem(
      "currentclicknum" + objSearch.pid + objSearch.taskid + objSearch.hid
    )
  : "0";
let nodiaoyong =
  localStorage.getItem(
    "nodiaoyong" + objSearch.pid + objSearch.taskid + objSearch.hid
  ) || "0";
let timechuo = parseInt(new Date().getTime() / 1000);
let wodesear = [];
if (Number(timechuo) - Number(objSearch.nowtime) <= 5) {
  if (objSearch.tfinsh === "") {
    // return
  }
  if (objSearch.pid) {
    if (nodiaoyong == "1" && objSearch.tfinsh == "0") {
      currentclicknum > 0 ? currentclicknum-- : currentclicknum;
      localStorage.clear();
      localStorage.setItem(
        "currentclicknum" + objSearch.pid + objSearch.taskid + objSearch.hid,
        currentclicknum
      );
    }
    nodiaoyong = objSearch.tfinsh || "0";
    console.log(nodiaoyong == "1");
    if (nodiaoyong == "0") {
      // localStorage.clear()
      // localStorage.setItem("currentclicknumDetail" + objSearch.pid + objSearch.taskid + objSearch.hid + todaytime, currentclicknum)
    } else {
      nodiaoyong = "1";
      localStorage.setItem(
        "nodiaoyong" + objSearch.pid + objSearch.taskid + objSearch.hid,
        "1"
      );
    }
  }
}
let renwuOverenan = "任务已完成，请您明日再来吧~";
let sumclicknum = "0";
// 广告曝光的次数
let showNum = 0;
let bottomContent = "";
let currentCity = "";
try {
  currentCity = lo.substring(0, lo.length - 1);
} catch (error) {
  currentCity = "上海";
}
// const currentCity = lo.substring(0, lo.length - 1)
// let currentCity = ''
console.log(currentCity);
let jumppath = window.location.pathname;
if (jumppath === "/") {
  jumppath = "/static/webtest/bdss/";
}
if (jumppath.indexOf("index") != "-1") {
  jumppath = jumppath.replace("index", "detail");
} else {
  jumppath = jumppath + "detail.html";
}

jumppath += "?id=";
// const jumppath = "/static/web/baselist/detail.html?id="
const hudongList = [
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_1.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "红包福利大转盘",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_2.gif?x-oss-process=image/resize,w_400,h_200",
    tielee: "点击领福气",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_3.gif?x-oss-process=image/resize,w_400,h_200",
    tielee: "红包砸中你了",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_4.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "今日福利，限时派送",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_5.gif?x-oss-process=image/resize,w_400,h_200",
    tielee: "打开红包,领福利",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_6.gif?x-oss-process=image/resize,w_400,h_200",
    tielee: "获得一笔奖励金，点我刮奖",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_7.png?x-oss-process=image/resize,w_400,h_200",
    tielee: "您有一笔现金，点我领取",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_8.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "签到好礼，点我领取",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_9.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "话费红包，点我抽奖",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_10.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "恭喜您被红包砸中了，点我领奖",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_11.png?x-oss-process=image/resize,w_400,h_200",
    tielee: "刮开有惊喜",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_12.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "恭喜您中奖了，点击领取",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_13.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "限时领取现金红包，点击领取",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_14.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "玩转盘，领大奖",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_15.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "天天抢红包",
  },
  {
    img: "https://static.zixunkankan.xyz/img/hu/hu_16.jpg?x-oss-process=image/resize,w_400,h_200",
    tielee: "领红包，拿大奖",
  },
];
$(function () {
  // 头部导航区域
  // $('#first div').css('background', '#FFA129')
  $(".box-item").on("click", ".item-group", function () {
    // console.log(111)
    $(".item-group").removeClass("active");
    $(".item-group div").css("background", "transparent");
    $(this).addClass("active");
    $(this).children("div").css("background", bodyColor);
    // 点击居中的方法
    var moveX = $(this).position().left + $(this).parent().scrollLeft();
    var pageX = document.documentElement.clientWidth;
    // 当前点击的盒子的宽度
    var blockWidth = $(this).width();
    var left = moveX - pageX / 2 + blockWidth / 2;
    $(".box-item").scrollLeft(left);
  });
  $(".oneArtic").on("click", function () {
    console.log(111);
  });
  if (
    objSearch.hid == "275" ||
    objSearch.hid == "368" ||
    objSearch.hid == "372" ||
    objSearch.hid == "373"
  ) {
    $(".customer").hide();
  }
  if (objSearch.pid == "90007" || objSearch.md == "yk") {
    $(".customer").hide();
  }
});

function getListcs(e, yema, ruleshow1 = ruleshow) {
  let ruleshow = ruleshow1;
  let yyy = getRandomNumber(1, Number(tbsRedShiedCity) - Number(tbsRed));
  $.ajax({
    // url: "https://www.zixunkankan.xyz/api/new/allnewList",
    url: "https://www.zixunkankan.xyz/api/new/newlist",
    method: "GET",
    data: {
      imagetype: "3",
      format: "json",
      type: e,
      page: yema,
      // page: yyy,
      // begin: tbsRed,
      // end: tbsRedShiedCity,
      // stemp: new Date().getTime()
    },
    // async: false,
    success: async function (res) {
      getList("0", pageSize);
      res.forEach((item) => {
        let pic = JSON.parse(item.contentimg);
        // console.log(pic)
        item.imgList = [];
        pic.forEach((it, index) => {
          const regex = new RegExp(
            "http://51youxi66.top/static/pipiNews",
            "gi"
          );
          it.img = it.img.replace(regex, "http://static.zixunkankan.xyz");
          it.img += "?x-oss-process=image/resize,w_240,h_200";
          if (index < 3) {
            item.imgList.push(it.img);
          }
        });
      });

      let index = 0;
      onexunhuan = true;
      let cswordsList = await getnewtitleci(40);
      for (let i = 0; i < ruleshow.length; i++) {
        if (ruleshow[i] == "ct") {
          addThreePic(res[index]);
          index++;
        }
        // if (ruleshow[i] == 'cs') {
        //     addThreePic2(res[index], i, 'cs')
        //     index++
        // }
        if (
          ruleshow[i] == "cs" ||
          ruleshow[i] == "bs" ||
          ruleshow[i] == "cd" ||
          ruleshow[i] == "csa" ||
          ruleshow[i] == "bsa" ||
          ruleshow[i] == "cda" ||
          ruleshow[i] == "cst" ||
          ruleshow[i] == "bst" ||
          ruleshow[i] == "cdt"
        ) {
          addThreePic4(
            res[index],
            i,
            ruleshow[i],
            contentTitleWeight,
            cswordsList,
            onexunhuan
          );
          index++;
          onexunhuan = false;
        }
        if (ruleshow[i] == "xm") {
          getxiaomiad();
        }
        if (ruleshow[i] == "ca") {
          addOnePic(res[index]);
          index++;
        }
        if (ruleshow[i] == "t") {
          addTbsAd();
        }
        if (ruleshow[i] == "h") {
          addhudongPic();
        }
        if (ruleshow[i] == "s") {
          addsogoAd("0");
        }
        if (ruleshow[i] == "s1") {
          addsogoAd("1");
        }
        if (ruleshow[i] == "s2") {
          addsogoAd("2");
        }
        if (ruleshow[i] == "s3") {
          addsogoAd("3");
        }
        if (ruleshow[i] == "s4") {
          addsogoAd("4");
        }
        if (ruleshow[i] == "s5") {
          addsogoAd("5");
        }
        if (ruleshow[i] == "s6") {
          addsogoAd("6");
        }
        if (ruleshow[i] == "ts") {
          addTbsssAd();
        }
        if (ruleshow[i] == "tv") {
          addTbsvvAd();
        }
        if (ruleshow[i] == "y") {
          addYlhAd1("y");
        }
        if (ruleshow[i] == "y1") {
          addYlhAd1("y1");
        }
        if (ruleshow[i] == "y2") {
          addYlhAd1("y2");
        }
        if (ruleshow[i] == "y3") {
          addYlhAd1("y3");
        }
        if (ruleshow[i].indexOf("z") != "-1") {
          addzhitou(ruleshow[i]);
        }
      }
      //
      if (ruleshow.indexOf("y") != "-1" || popupAdid.length < 20) {
        addYlhAd(youCode && youCode?.trim(), ruleshow, "home", popupAdid);
        if (!onceMethod) {
          setTimeout(() => {
            addYlhAd(youCode && youCode?.trim(), ruleshow, "home", popupAdid);
          }, 1000);
          onceMethod = true;
        }
      }

      getBindMove();
      setTimeout(() => {
        scriptLoading();
        lazyLoading();
      }, 100);
    },
  });
}
let pageLoadingnum = 1;
// 获取列表
function getList(e, yema, ruleshow1 = ruleshow) {
  console.log(pageLoadingnum);
  if (pageLoadingnum > 3) {
    return;
  }
  pageLoadingnum++;
  let ruleshow = ruleshow1;
  let yyy = 1;
  let nnn = true;
  if (
    ruleshow.indexOf("cs") != -1 ||
    ruleshow.indexOf("bs") != -1 ||
    ruleshow.indexOf("cd") != -1
  ) {
    // 里面包含的有cs
    nnn = false;
  } else {
    //没有cs的
    nnn = false;
  }
  yyy = nnn
    ? getRandomNumber(1, Number(tbsRedShiedCity) - Number(tbsRed))
    : getRandomNumber(1, 80);
  let datas = {};
  if (nnn) {
    if (
      ruleshow.indexOf("cst") != -1 ||
      ruleshow.indexOf("bst") != -1 ||
      ruleshow.indexOf("cdt") != -1
    ) {
      datas = {
        // page: yyy,
        // begin: tbsRed,
        // end: tbsRedShiedCity,
        stemp: new Date().getTime(),
        all: "1",
      };
    } else {
      datas = {
        // page: yyy,
        // begin: tbsRed,
        // end: tbsRedShiedCity,
        stemp: new Date().getTime(),
        all: "1",
      };
    }
  } else {
    datas = {
      imagetype: "3",
      format: "json",
      type: isdelicious ? "6" : e,
      page: isdelicious ? getRandomNumber(1, 20) : yyy,
    };
  }
  $.ajax({
    // url: "https://www.zixunkankan.xyz/api/new/newlist",
    url: `${
      nnn
        ? "https://www.zixunkankan.xyz/api/new/allnewList"
        : "https://www.zixunkankan.xyz/api/new/newlist"
    }`,
    method: "GET",
    data: datas,
    async: false,
    success: async function (res) {
      res.forEach((item) => {
        let pic = JSON.parse(item.contentimg);
        item.imgList = [];
        pic.forEach((it, index) => {
          const regex = new RegExp(
            "http://51youxi66.top/static/pipiNews",
            "gi"
          );
          it.img = it.img.replace(regex, "https://static.zixunkankan.xyz");
          it.img += "?x-oss-process=image/resize,w_240,h_200";
          if (index < 3) {
            item.imgList.push(it.img);
          }
        });
      });
      let index = 0;
      onexunhuan = true;
      let cswordsList = await getnewtitleci(40, objSearch.hid);
      // getSearchvaluereturn('0')
      for (let i = 0; i < ruleshow.length; i++) {
        if (ruleshow[i] == "ct") {
          addThreePic(res[index]);
          index++;
        }
        if (
          ruleshow[i] == "cs" ||
          ruleshow[i] == "bs" ||
          ruleshow[i] == "cd" ||
          ruleshow[i] == "csa" ||
          ruleshow[i] == "bsa" ||
          ruleshow[i] == "cda" ||
          ruleshow[i] == "cst" ||
          ruleshow[i] == "bst" ||
          ruleshow[i] == "cdt"
        ) {
          addThreePic4(
            res[index],
            i,
            ruleshow[i],
            contentTitleWeight,
            cswordsList,
            onexunhuan
          );
          index++;
          onexunhuan = false;
        }
        if (ruleshow[i] == "xm") {
          getxiaomiad();
        }
        if (ruleshow[i] == "ca") {
          addOnePic(res[index]);
          index++;
        }
        if (ruleshow[i] == "hl") {
          // gethailiangad(gethailiangdom(),objSearch.userid,objSearch.imei,objSearch.androidid,objSearch.oaid,objSearch.mac)
        }
        if (ruleshow[i] == "t") {
          addTbsAd();
        }
        if (ruleshow[i] == "b") {
          addbaduAd(0);
        }
        if (ruleshow[i] == "b1") {
          addbaduAd(1);
        }
        if (ruleshow[i] == "b2") {
          addbaduAd(2);
        }
        if (ruleshow[i] == "b3") {
          addbaduAd(3);
        }
        if (ruleshow[i] == "d") {
          addbadutwoAd(0);
        }
        if (ruleshow[i] == "d1") {
          addbadutwoAd(1);
        }
        if (ruleshow[i] == "d2") {
          addbadutwoAd(2);
        }
        if (ruleshow[i] == "d3") {
          addbadutwoAd(3);
        }
        if (ruleshow[i] == "xc") {
          addxingchencode(0);
        }
        if (ruleshow[i] == "xc1") {
          addxingchencode(1);
        }
        if (ruleshow[i] == "xc2") {
          addxingchencode(2);
        }
        if (ruleshow[i] == "xc3") {
          addxingchencode(3);
        }
        if (ruleshow[i] == "h") {
          addhudongPic();
        }
        if (ruleshow[i] == "s") {
          addsogoAd("0");
        }
        if (ruleshow[i] == "s1") {
          addsogoAd("1");
        }
        if (ruleshow[i] == "s2") {
          addsogoAd("2");
        }
        if (ruleshow[i] == "s3") {
          addsogoAd("3");
        }
        if (ruleshow[i] == "s4") {
          addsogoAd("4");
        }
        if (ruleshow[i] == "s5") {
          addsogoAd("5");
        }
        if (ruleshow[i] == "s6") {
          addsogoAd("6");
        }
        if (ruleshow[i] == "ts") {
          addTbsssAd();
        }
        if (ruleshow[i] == "tv") {
          addTbsvvAd();
        }
        if (ruleshow[i] == "y") {
          addYlhAd1("y");
        }
        if (ruleshow[i] == "y1") {
          addYlhAd1("y1");
        }
        if (ruleshow[i] == "y2") {
          addYlhAd1("y2");
        }
        if (ruleshow[i] == "y3") {
          addYlhAd1("y3");
        }

        if (ruleshow[i].indexOf("z") != "-1") {
          addzhitou(ruleshow[i]);
        }
      }
      //
      if (ruleshow.indexOf("y") != "-1" || popupAdid.length < 20) {
        addYlhAd(youCode?.trim(), ruleshow, "home", popupAdid);
        if (!onceMethod) {
          setTimeout(() => {
            addYlhAd(youCode?.trim(), ruleshow, "home", popupAdid);
          }, 1000);
          onceMethod = true;
        }
      }

      getBindMove();
      setTimeout(() => {
        scriptLoading();
        lazyLoading();
      }, 100);
    },
  });
}
// 搜索广告
function getSearchvalue(e) {
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/getkeyword",
    method: "GET",
    dataType: "json",
    success: function (res) {
      let umjumpSearch = "https://yz.m.sm.cn/s?from=" + e + "&q=" + res.msg;
      console.log(umjumpSearch);
      window.location.href = umjumpSearch;
    },
  });
}
let nnn = [];

function getSearchvaluereturn(e) {
  let a = "";
  if (e == "0") {
    $.ajax({
      url: "https://www.zixunkankan.xyz/api/getkeyword",
      method: "GET",
      dataType: "json",
      data: {
        num: "36",
      },
      async: false,
      success: function (res) {
        res.msg = res.msg.substring(1, res.msg.length - 1);
        res.msg = res.msg.replace(/'/g, "");
        nnn = res.msg.split(",");
        let enterb = nnn.slice(e, 3);

        a = enterb.join("#");
      },
    });
  } else {
    let enterb2 = nnn.slice(e * 3, (e + 1) * 3);
    a = enterb2.join("#");
  }

  return a;
}

function getSeardetail(e) {
  let a = "";
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/getkeyword",
    method: "GET",
    dataType: "json",
    data: {
      num: "36",
    },
    async: false,
    success: function (res) {
      res.msg = res.msg.substring(1, res.msg.length - 1);
      res.msg = res.msg.replace(/'/g, "");
      a = res.msg.split(",");
    },
  });

  return a;
}
// 添加三图
function addThreePic(item) {
  // <img src="" data-src="${item.imgList[0]}" alt="">
  //     <img src="" data-src="${item.imgList[1]}" alt="">
  //     <img src="" data-src="${item.imgList[2]}" alt="">
  let henDom = `<div style="font-weight:${contentTitleWeight}" class="oneArtic bottom-line wenzhangchlick" tid="${item.tid}"><div class="titleBix">
        ${item.title}
    </div>
    <div class="ingBox">
       
        <div><img src="" data-src="${item.imgList[0]}" alt=""></div>
        <div><img src="" data-src="${item.imgList[1]}" alt=""></div>
            <div><img src="" data-src="${item.imgList[2]}" alt=""></div>
    </div>
    <div class="ggLaiyuan">${item.source}</div></div>`;
  $("#snatuBox").append(henDom);
}

function getcsdetail(e) {
  let strdet = "";
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/new/newinfo",
    method: "GET",
    data: {
      tid: e,
      stemp: new Date().getTime(),
    },
    async: false,
    success: function (res) {
      let newobj = JSON.parse(res);
      let contentadd = JSON.parse(newobj.contenttext);
      contentadd.forEach((item) => {
        strdet += item.text;
      });
      // let enterb2 = nnn.slice(e * 3, (e + 1) * 3)
      // a = enterb2.join('#')
    },
  });
  return strdet;
}
let csnnn = [];
let csnnntitle = "";
let cnssnum = 0;
let mycsdtelist = [];
let mycsdtelisttitle = [];

// <img src="${item.imgList[0]}" data-src="" alt="">
// <img src="${item.imgList[1]}" alt="">
// <img src="${item.imgList[2]}" alt="">
// 添加单图
function addOnePic(item) {
  let henDom = `<div class="threeArtic bottom-line wenzhangchlick" tid="${item.tid}">
    <div class="shangTiop">
        <div class="imgDant">
            <img src="" data-src="${item.imgList[0]}" alt="">
        </div>
        <div class="leftTitle">
            ${item.title}
        </div>
    </div>
    <div class="ggLaiyuan">${item.source}</div></div>
</div>`;
  $("#snatuBox").append(henDom);
}
// 添加互动广告
function addhudongPic() {
  let trmahb = hudongList[getRandomNumber(0, 15)];
  let henDom = `
    <div class="threeArtic bottom-line admoveNum">
    <div class="titleBix">${trmahb.tielee}</div>
    <div class="shangTiophudong" style="margin-top:10px;">
            <div>
                <img src="" data-src="${trmahb.img}" alt="">
            </div>
    </div>
    <div class="ggLaiyuan">广告</div>
</div>`;
  $("#snatuBox").append(henDom);
}

// window.handleAdError = function(errorid) {
//     console.log(9988)
//     console.log(errorid)
//     alert(errorid + '---adError')
// }

// window.handleAdEvent = function() {
//     const evt = arguments[0];
//     console.log(evt + '---adevent')
// }

// 添加腾讯广告
function addTbsAd() {
  var sc = document.createElement("script");
  var wodeid = document.getElementById("snatuBox");
  sc.type = "text/javascript";
  var rand = getRandomNumber(1000, 3000);
  // sc.setAttribute("src", "https://tbsad.imtt.qq.com/tbs/jssdk/js/formal/TBSJSSDK.js?rand=" + rand);
  sc.setAttribute(
    "data-src",
    "https://tbsad.imtt.qq.com/tbs/jssdk/js/formal/TBSJSSDK.js?rand=" + rand
  );
  sc.setAttribute("adkey", tengcnetCode);
  // sc.setAttributeNS(null, 'onAdError', `handleAdError`)
  // sc.setAttribute('onAdError', handleAdError)
  // sc.setAttribute('onAdEvent', handleAdEvent)
  var divs = document.createElement("div");
  divs.setAttribute("class", "tbsclass admoveNum");
  divs.appendChild(sc);
  wodeid.appendChild(divs);
}
// 腾讯小图广告
function addTbsssAd() {
  var sc = document.createElement("script");
  var wodeid = document.getElementById("snatuBox");
  sc.type = "text/javascript";
  var rand = getRandomNumber(1000, 3000);
  sc.setAttribute(
    "src",
    "https://tbsad.imtt.qq.com/tbs/jssdk/js/formal/TBSJSSDK.js?rand=" + rand
  );
  sc.setAttribute("adkey", tbsSCode);
  // sc.setAttribute("adkey", "3c1d3c33959c6e74762a653974ca2280");
  var divs = document.createElement("div");
  divs.setAttribute("class", "tbsclass admoveNum");
  divs.appendChild(sc);
  wodeid.appendChild(divs);
}
// 腾讯视频广告
function addTbsvvAd() {
  var sc = document.createElement("script");
  var wodeid = document.getElementById("snatuBox");
  sc.type = "text/javascript";
  var rand = getRandomNumber(1000, 3000);
  sc.setAttribute(
    "src",
    "https://tbsad.imtt.qq.com/tbs/jssdk/js/formal/TBSJSSDK.js?rand=" + rand
  );
  sc.setAttribute("adkey", tbsVCode);
  // sc.setAttribute("adkey", "3c1d3c33959c6e74762a653974ca2280");
  var divs = document.createElement("div");
  divs.setAttribute("class", "tbsclass admoveNum");
  divs.appendChild(sc);
  wodeid.appendChild(divs);
}
//百度广告
function addbaduAd(e) {
  let bcode = baiduCode.split(" ")[e];
  let baidukkk = getRandomNumber(9999, 99999);
  var div_bottom = document.createElement("div");
  div_bottom.setAttribute("class", `baiduad${baidukkk}`);

  var div_bottomfa = document.createElement("div");
  div_bottomfa.setAttribute("style", "position:relative");

  var zhezhao = document.createElement("div");
  zhezhao.setAttribute("class", "zhezhaopopuclass");
  // div_bottomfa.appendChild(div_bottom)
  div_bottomfa.appendChild(zhezhao);
  $("#snatuBox").append(div_bottom);
  // $("#snatuBox").append(`<div class="baiduad${baidukkk}"></div>`)
  let bdobj = {
    id: bcode,
    container: `baiduad${baidukkk}`,
    async: true,
  };
  try {
    // window.slotbydup.push(bdobj)
    (window.slotbydup = window.slotbydup || []).push(bdobj);
  } catch (error) {}

  // (window.slotbydup = window.slotbydup || []).push(bdobj);
}

function addxingchencode(e) {
  let bcode = AdShowCity.split(" ")[e];
  let div_bottom = document.createElement("div");
  let d = document.createElement("script");
  d.setAttribute("type", "text/javascript");
  d.setAttribute("smua", bcode);
  d.setAttribute("src", "https://www.sinawap.com/smu/o.js");
  div_bottom.appendChild(d);
  document.getElementById("snatuBox").appendChild(div_bottom);
}

function addbadutwoAd(e) {
  return;
  let bcode = tbsRedShowCity.split(" ")[e];
  let baidukkk = getRandomNumber(9999, 99999);
  var div_bottom = document.createElement("div");
  div_bottom.setAttribute("class", `baiduad${baidukkk}`);
  var div_bottomfa = document.createElement("div");
  div_bottomfa.setAttribute("style", "position:relative");
  var zhezhao = document.createElement("div");
  zhezhao.setAttribute("class", "zhezhaopopuclass");

  div_bottomfa.appendChild(zhezhao);
  // div_bottomfa.appendChild(div_bottom)
  // $("#snatuBox").append(div_bottomfa)
  $("#snatuBox").append(div_bottom);
  let bdobj = {
    id: bcode,
    container: `baiduad${baidukkk}`,
    async: true,
  };
  try {
    window._jcs.slotbyJcs(bdobj.id, bdobj.container, 2);
  } catch (error) {}
}

// let allrellow = true
//     // 优量汇广告
// function addYlhAd() {
//     // let youCodecode = ''
//     // if (youCode && youCode.indexOf(' ') == '-1') {
//     //     youCodecode = youCode
//     // } else {
//     //     let youlist = youCode.split(' ')
//     //     console.log(youlist)
//     //     let youliangnum = localStorage.getItem('youliangnum') || 0
//     //     youliangnum++
//     //     localStorage.setItem('youliangnum', youliangnum)
//     //     youCodecode = youlist[youliangnum % youlist.length]
//     // }
//     console.log(youCode.split(' ')[0])
//     console.log(youCode.split(' ')[1])
//     console.log(youCode.split(' ')[2])
//     console.log(youCode.split(' ')[3])
//     if (!allrellow) {
//         try {
//             if (youCode.split(' ')[0]) {
//                 window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[0])
//             }
//             if (youCode.split(' ')[1]) {
//                 window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[1])
//             }
//             if (youCode.split(' ')[2]) {
//                 window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[2])
//             }
//             if (youCode.split(' ')[3]) {
//                 window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[3])
//             }
//         } catch (error) {

//         }
//         // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[0])
//         // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[1])
//         // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[2])
//         // window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[3])
//         return
//     }
//     window.TencentGDT = window.TencentGDT || [];
//     // let ylhnum = $('.getadnumnum').length
//     let ylhnum = 0
//     ruleshow.forEach(item => {
//         if (item.substring(0, 1) === 'y') {
//             ylhnum++
//         }
//     })
//     if (Number(ylhnum) >= 10) {
//         ylhnum = 10
//     }
//     console.log('1111176562')
//     console.log(youCode.split(' ')[0])
//     ylhnum = 10
//     if (youCode.split(' ')[0]) {
//         console.log('12')
//         window.TencentGDT.push({
//             app_id: appid, // {String} - appid - 必填
//             placement_id: youCode.split(' ')[0], // {String} - 广告位id - 必填
//             type: 'native', // {String} - 原生广告类型 - 必填
//             // muid_type: '1', // {String} - 移动终端标识类型，1：imei，2：idfa，3：mac号 - 选填
//             // muid: '******', // {String} - 加密终端标识，详细加密算法见API说明 -  选填
//             count: ylhnum, // {Number} - 拉取广告的数量，默认是3，最高支持10 - 选填
//             onComplete: function(res) {
//                 console.log(res)
//                 let ylhlist = []
//                 $('.ylihclass').each((index, item) => {
//                     ylhlist.push($(item).attr('id'))
//                     $(item).removeClass("ylihclass");
//                     $(item).addClass(`${$(item).attr('id')}+11`);
//                 })
//                 if (res && res.constructor === Array) {
//                     // 原生模板广告位调用 window.TencentGDT.NATIVE.renderAd(res[0], 'containerId') 进行模板广告的渲染
//                     // res[0] 代表取广告数组第一个数据
//                     // containerId：广告容器ID
//                     res.forEach((item, index) => {
//                         window.TencentGDT.NATIVE.renderAd(item, ylhlist[index])
//                     })
//                 } else {
//                     // 加载广告API，如广告回调无广告，可使用loadAd再次拉取广告
//                     // 注意：拉取广告频率每分钟不要超过20次，否则会被广告接口过滤，影响广告位填充率
//                     // setTimeout(function() {
//                     //     window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[0])
//                     // }, 3000)
//                 }
//             }
//         });
//     }
//     // /*
//     if (youCode.split(' ')[1]) {
//         window.TencentGDT.push({
//             app_id: appid, // {String} - appid - 必填
//             placement_id: youCode.split(' ')[1], // {String} - 广告位id - 必填
//             type: 'native', // {String} - 原生广告类型 - 必填
//             // muid_type: '1', // {String} - 移动终端标识类型，1：imei，2：idfa，3：mac号 - 选填
//             // muid: '******', // {String} - 加密终端标识，详细加密算法见API说明 -  选填
//             count: ylhnum, // {Number} - 拉取广告的数量，默认是3，最高支持10 - 选填
//             onComplete: function(res) {

//                 let ylhlist = []
//                 $('.y1lihclass').each((index, item) => {
//                     ylhlist.push($(item).attr('id'))
//                     $(item).removeClass("y1lihclass");
//                     $(item).addClass(`${$(item).attr('id')}+11`);
//                 })
//                 if (res && res.constructor === Array) {
//                     // 原生模板广告位调用 window.TencentGDT.NATIVE.renderAd(res[0], 'containerId') 进行模板广告的渲染
//                     // res[0] 代表取广告数组第一个数据
//                     // containerId：广告容器ID
//                     res.forEach((item, index) => {
//                         window.TencentGDT.NATIVE.renderAd(item, ylhlist[index])
//                     })

//                 } else {
//                     // 加载广告API，如广告回调无广告，可使用loadAd再次拉取广告
//                     // 注意：拉取广告频率每分钟不要超过20次，否则会被广告接口过滤，影响广告位填充率
//                     // setTimeout(function() {
//                     //     window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[1])
//                     // }, 3000)
//                 }
//             }
//         });
//     }
//     if (youCode.split(' ')[2]) {
//         window.TencentGDT.push({
//             app_id: appid, // {String} - appid - 必填
//             placement_id: youCode.split(' ')[2], // {String} - 广告位id - 必填
//             type: 'native', // {String} - 原生广告类型 - 必填
//             // muid_type: '1', // {String} - 移动终端标识类型，1：imei，2：idfa，3：mac号 - 选填
//             // muid: '******', // {String} - 加密终端标识，详细加密算法见API说明 -  选填
//             count: ylhnum, // {Number} - 拉取广告的数量，默认是3，最高支持10 - 选填
//             onComplete: function(res) {

//                 let ylhlist = []
//                 $('.y2lihclass').each((index, item) => {
//                     ylhlist.push($(item).attr('id'))
//                     $(item).removeClass("y2lihclass");
//                     $(item).addClass(`${$(item).attr('id')}+11`);
//                 })
//                 if (res && res.constructor === Array) {
//                     // 原生模板广告位调用 window.TencentGDT.NATIVE.renderAd(res[0], 'containerId') 进行模板广告的渲染
//                     // res[0] 代表取广告数组第一个数据
//                     // containerId：广告容器ID
//                     res.forEach((item, index) => {
//                         window.TencentGDT.NATIVE.renderAd(item, ylhlist[index])
//                     })

//                 } else {
//                     // 加载广告API，如广告回调无广告，可使用loadAd再次拉取广告
//                     // 注意：拉取广告频率每分钟不要超过20次，否则会被广告接口过滤，影响广告位填充率
//                     setTimeout(function() {
//                         window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[2])
//                     }, 3000)
//                 }
//             }
//         });
//     }
//     if (youCode.split(' ')[3]) {
//         window.TencentGDT.push({
//             app_id: appid, // {String} - appid - 必填
//             placement_id: youCode.split(' ')[3], // {String} - 广告位id - 必填
//             type: 'native', // {String} - 原生广告类型 - 必填
//             // muid_type: '1', // {String} - 移动终端标识类型，1：imei，2：idfa，3：mac号 - 选填
//             // muid: '******', // {String} - 加密终端标识，详细加密算法见API说明 -  选填
//             count: ylhnum, // {Number} - 拉取广告的数量，默认是3，最高支持10 - 选填
//             onComplete: function(res) {

//                 let ylhlist = []
//                 $('.y3lihclass').each((index, item) => {
//                     ylhlist.push($(item).attr('id'))
//                     $(item).removeClass("y3lihclass");
//                     $(item).addClass(`${$(item).attr('id')}+11`);
//                 })
//                 if (res && res.constructor === Array) {
//                     // 原生模板广告位调用 window.TencentGDT.NATIVE.renderAd(res[0], 'containerId') 进行模板广告的渲染
//                     // res[0] 代表取广告数组第一个数据
//                     // containerId：广告容器ID
//                     res.forEach((item, index) => {
//                         window.TencentGDT.NATIVE.renderAd(item, ylhlist[index])
//                     })

//                 } else {
//                     // 加载广告API，如广告回调无广告，可使用loadAd再次拉取广告
//                     // 注意：拉取广告频率每分钟不要超过20次，否则会被广告接口过滤，影响广告位填充率
//                     setTimeout(function() {
//                         window.TencentGDT.NATIVE.loadAd(youCode.split(' ')[3])
//                     }, 3000)
//                 }
//             }
//         });
//     }
//     // */
// (function() {
//     var doc = document,
//         h = doc.getElementsByTagName('head')[0],
//         s = doc.createElement('script');
//     s.async = true;
//     s.src = '//qzs.qq.com/qzone/biz/res/i.js';
//     h && h.insertBefore(s, h.firstChild);
// })();
// allrellow = false
// }

function addYlhAd1(e) {
  let wodeid = document.getElementById("snatuBox");
  let divs = document.createElement("div");
  var rand = getRandomNumber(1000, 3000);
  divs.setAttribute("id", `ylhid${rand}`);
  switch (e) {
    case "y":
      divs.setAttribute("class", `ylihclass getadnumnum`);
      break;
    case "y1":
      divs.setAttribute("class", `y1lihclass getadnumnum`);
      break;
    case "y2":
      divs.setAttribute("class", `y2lihclass getadnumnum`);
      break;
    case "y3":
      divs.setAttribute("class", `y3lihclass getadnumnum`);
      break;
    default:
      divs.setAttribute("class", `ylihclass getadnumnum`);
  }
  wodeid.appendChild(divs);
}
// 添加直投广告
function addzhitou(e) {
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/new/zcontent",
    method: "GET",
    data: {
      typecode: e,
    },
    async: false,
    dataType: "json",
    success: function (res) {
      res = res.data[0];
      let isopen = res.isOpen;

      if (res.ShowCity && res.ShowCity.indexOf(currentCity) == "-1") {
        // 展示的城市没有当前城市、
        isopen = "1";
      }
      if (res.AdShiedCity && res.AdShiedCity.indexOf(currentCity) != "-1") {
        isopen = "0";
      }
      if (isopen == "1") {
        // 控制展示时间
        let shangxian = res.beginTime;
        let xiaxian = res.endTime;
        let myDate = new Date();
        let hourd = myDate.getHours();
        if (hourd >= Number(shangxian) && Number(xiaxian) >= hourd) {
          isopen = "1";
        } else {
          isopen = "0";
        }
      }
      jumpzhitou = res.url;
      zhitouid = res.id;
      zhitouTypecode = res.typeCode;

      if (isopen == "1") {
        let zhitouone = "";
        if (res.imgurl3 == null) {
          //    单图的广告
          // <div class="titleBix">
          //     ${res.typeName}
          // </div>
          zhitouone = `
                <div class="admoveNum oneArtic bottom-line zhitouclick" zhitouid=${
                  res.id
                } zhitouTypecode=${res.typeCode} jumpUrl=${res.url}>
                    ${
                      res.typeName == 0
                        ? ""
                        : '<div class="titleBix">' + res.typeName + "</div>"
                    }
                    <div class="ingBoxzhitou1">
                        <img src="" data-src="${res.imgurl}" alt="">
                    </div>
                    <div class="ggLaiyuan">直投广告</div>
                </div>`;
        } else {
          //    三图的广告
          zhitouone = `
                    <div class="admoveNum oneArtic bottom-line  zhitouclick" zhitouid=${
                      res.id
                    } zhitouTypecode=${res.typeCode} jumpUrl=${res.url}>
                        ${
                          res.typeName == 0
                            ? ""
                            : '<div class="titleBix">' + res.typeName + "</div>"
                        }
                        <div class="ingBox">
                            <img data-src="${res.imgurl}" alt="">
                            <img data-src="${res.imgurl2}" alt="">
                            <img data-src="${res.imgurl3}" alt="">
                        </div>
                        <div class="ggLaiyuan">直投广告</div>
                    </div>`;
        }
        $("#snatuBox").append(zhitouone);
      }
    },
  });
}

function addPupuad(tengcnetCode) {
  var sc = document.createElement("script");
  var wodeid = document.getElementById("popupTbccode");
  sc.type = "text/javascript";
  var rand = getRandomNumber(1000, 9999);
  sc.setAttribute(
    "src",
    "https://tbsad.imtt.qq.com/tbs/jssdk/js/formal/TBSJSSDK.js?rand=" + rand
  );
  sc.setAttribute("adkey", tengcnetCode);
  var divs = document.createElement("div");
  divs.setAttribute("class", "tbsclass");
  divs.appendChild(sc);
  wodeid.appendChild(divs);
}
// 添加搜狗广告
// let arrnum = getRandomNumber(0, sogocode.split(' ').length)
let arrnum = "0";

function addsogoAd(e) {
  let sogoarr = sogocode.split("-");
  let sogonewcode = sogoarr[e];
  let sonu = sogonewcode.indexOf("sogou_wap_");
  const bannerreple = sogonewcode.substring(sonu, sonu + 17);
  console.log(bannerreple);
  const randmeid = `sogoid-${getRandomNumber(10000, 99999)}`;
  const div = document.createElement("div");
  const mysogoad = sogonewcode.replace(new RegExp(bannerreple, "g"), randmeid);
  div.innerHTML = mysogoad;
  $("#snatuBox").append(div);
}

function addsogoAd1(e) {
  let sogoarr = sogocode.split("-");
  console.log(sogoarr);
  // let sogonewcode = sogoarr[Number(e) - 1]
  let sogonewcode = sogoarr[e];
  // arrnum++
  // let sogonewcode = sogoarr[arrnum % (sogoarr.length)]
  var idd = getRandomNumber(0, 1000000);
  let test = document.getElementById("snatuBox");
  var div_bottom = document.createElement("div");
  div_bottom.setAttribute("style", "position:relative");
  div_bottom.setAttribute("id", "sogou_wap_" + idd);
  div_bottom.setAttribute("class", "admoveNum");
  var zhezhao = document.createElement("div");
  zhezhao.setAttribute("class", "zhezhaopopuclass");
  div_bottom.appendChild(zhezhao);
  test.appendChild(div_bottom);
  var sogou_div = document.getElementById("sogou_wap_" + idd);
  window.sogou_un = window.sogou_un || [];
  window.sogou_un.push({
    // id: sogocode,
    id: sogonewcode,
    ele: sogou_div,
  });
}
// 广告的曝光和点击
function adexposure(type, count) {
  // type分为show和click
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/new/zshowClick",
    method: "GET",
    data: {
      hid: objSearch.hid,
      type: type,
      count: count,
    },
  });
}

function getBindMove() {
  Array.prototype.slice
    .apply(document.getElementsByClassName("admoveNum"))
    .forEach((img) => {
      // console.log(li)
      // console.log(111111111)
      // 页面卷去的距离
      let scrollTop = $(window).scrollTop();
      // 浏览器的窗口高度
      let chazhiHeight = $(window).height();
      if (
        $(img).offset().top - scrollTop > 0 &&
        $(img).offset().top - scrollTop < chazhiHeight
      ) {
        if ($(img).attr("ishow") == "0") {
          showNum++;
        }
        $(img).attr("ishow", "1");
      } else {
        $(img).attr("ishow", "0");
      }
    });
}
// 页面关闭之前调用这个方法
// window.onbeforeunload = function(event) {
//     if (objSearch.hid == '247') {

//     }
// }

const basesrc =
  "https://static.zixunkankan.xyz/2020-05-15/2/23da5ac3420d1f4b87790d0b91c690d1.jpg";

function getjumpqueiapp() {
  $.ajax({
    // url: `${baseuritest}/index/appoint_setting/`,
    url: `${baseuri}/index/appoint_setting/`,
    data: {
      hid: objSearch.hid,
    },
    success: function (res) {
      if (res.code === 202) {
        return;
      }
      let screencity = [];
      if (res.data.shield_city && res.data.shield_city != "null") {
        screencity = res.data.shield_city.split("/");
      }
      if (res.data.is_new === 2) {
        const loadingdiv = `<div class="jumpbox">
                <img class="myimh" data-src="https://static.zixunkankan.xyz/image628/guesssong/webp.webp" src="https://static.zixunkankan.xyz/image628/guesssong/webp.webp" alt="">
                </div>`;
        $(".box").hide();
        $(".articleList").hide();
        $("body").append(loadingdiv);
      }
      // screencity = []
      let terminal = "";
      if (Array.isArray(res.data.terminal)) {
        terminal = res.data.terminal.join("u");
      }
      let opennum =
        localStorage.getItem(
          "openqappnum" +
            objSearch.pid +
            objSearch.taskid +
            objSearch.hid +
            objSearch.userid
        ) || 0;
      if (opennum >= res.data.number) {
        res.data.is_open = 0;
      }
      try {
        if (
          res.data.is_open === 1 &&
          screencity.findIndex((item) => item == currentCity) == -1
        ) {
          opennum = Number(opennum) + 1;
          console.log(opennum);
          console.log("刷新次数");
          localStorage.setItem(
            "openqappnum" +
              objSearch.pid +
              objSearch.taskid +
              objSearch.hid +
              objSearch.userid,
            opennum
          );
          res.data.package = res.data.package
            ? res.data.package
            : "com.constellationhome.pipi";
          opprequiceapp(
            res.data.url,
            res.data.task_tid,
            terminal,
            res.data.package
          );
        }
      } catch (error) {
        // alert(error)
      }
    },
  });
}
// 第四部分
$(function () {
  if (objSearch.hid == "409") {
    jumpbaiduapp();
  }
  // if (objSearch.hid == '417') {
  //     const loadingdiv = `<div class="jumpbox">
  // <img class="myimh" data-src="https://static.zixunkankan.xyz/image628/guesssong/webp.webp" src="https://static.zixunkankan.xyz/image628/guesssong/webp.webp" alt="">
  // </div>`
  //     $('.box').hide()
  //     $('.articleList').hide()
  //     $('body').append(loadingdiv)
  // }
  let isopenrqi = sessionStorage.getItem("isopenapp") || "";
  // isopenrqi = ''
  if (!isopenrqi) {
    try {
      getjumpqueiapp();
    } catch (error) {}
    sessionStorage.setItem("isopenapp", "1");
  }
  // 获取跳转配置
  window.addEventListener(
    "error",
    function (e) {
      // console.log(e)
      // console.log(e.target.className)
      // console.log(e.target.tagName);
      if (
        e.target.tagName === "IMG" &&
        e.target.src.indexOf("zixunkankan.xyz") !== -1 &&
        e.target.className === "clickimg" &&
        e.target.src !== basesrc
      ) {
        console.log(e);
        console.log(e.target.src);
        e.target.src = basesrc;
        e.target.dataset.src = basesrc;
        // e.error = null
      }
    },
    true
  );

  function api_businesslines() {
    return new Promise((resolve, reject) => {
      new BMap.Geolocation().getCurrentPosition(
        function (r) {
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            var geoc = new BMap.Geocoder();
            geoc.getLocation(r.point, function (rs) {
              var addComp = rs.addressComponents;
              resolve(addComp.province);
            });
          } else {
            reject("failed");
          }
        },
        {
          enableHighAccuracy: true,
        }
      );
    });
  }

  // 监听图片
  // api_businesslines().then((res) => {
  //     currentCity = res.substring(0, res.length - 1)
  //     console.log(currentCity)
  //     hethidval()
  // })
  reportIp(objSearch.hid).then(
    (res) => {
      if (res.is_redirect === 1 && res.url) {
        // 重定向
        window.location.replace(res.url);
      }
    },
    (err) => {
      console.log(err);
    }
  );
  hethidval();
  window.focus();
  window.addEventListener("blur", function (e) {
    if (document.activeElement.tagName === "IFRAME") {
      clicknumjiajia();
    }
  });
  // let time = setInterval(() => {
  //     if (document.activeElement.tagName === 'IFRAME') {
  //         clicknumjiajia()
  //         clearInterval(time)
  //     }
  //     // }, 500)
  // }, 1)
  $(".customer").click(function () {
    window.location.href = `https://im.7x24cc.com/phone_webChat.html?accountId=N000000025068&chatId=5bf4f702-0eeb-40fc-aa64-f02c7edc85a1&visitorId=${objSearch.userid}-${objSearch.pid}`;
  });
});

function hethidval() {
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/new/newConfig?hid=" + objSearch.hid,
    // url: "https://gongziduan.com/api/new/newConfig?hid=" + objSearch.hid,
    method: "GET",
    dataType: "json",
    async: false,
    success: function (res) {
      try {
        if (res.url) {
          window.location.href = res.url;
          return;
        }
      } catch (error) {}
      try {
        if (navigator.userAgent.indexOf("TBS") == "-1") {
          res.adList = res.adList.replace(/ts/g, "h");
          res.adList = res.adList.replace(/tv/g, "h");
          res.adList = res.adList.replace(/ t/g, " h");
          res.adCityList = res.adCityList
            ? res.adCityList.replace(/ts/g, "h")
            : res.adCityList;
          res.adCityList = res.adCityList
            ? res.adCityList.replace(/tv/g, "h")
            : res.adCityList;
          res.adCityList = res.adCityList
            ? res.adCityList.replace(/ t/g, " h")
            : res.adCityList;
          // res.adCityList = res.adCityList.replace(/tv/g, "h")
          // res.adCityList = res.adCityList.replace(/ t/g, " h")
        }
      } catch (error) {}
      // bottomContent = res.bottomContent
      bottomContent = res.bottomContent == "或广告" ? "或广告" : "";
      // 是否立即跳转
      let goto = res.goto;
      let gotosuiji = getRandomNumber(0, 100);
      if (res.gotoShowCity && res.gotoShowCity.indexOf(currentCity) != "-1") {
        goto = "1";
      }
      if (
        res.gotoShiledCity &&
        res.gotoShiledCity.indexOf(currentCity) != "-1"
      ) {
        goto = "0";
      }
      if (goto == "1") {
        let gotojumpurl =
          res.gotoUrl +
          "?hid=" +
          objSearch.hid +
          "&userid=" +
          objSearch.userid +
          "&pid=" +
          objSearch.pid +
          "&taskid=" +
          objSearch.taskid +
          "&imei=" +
          objSearch.imei +
          "&nowtime=" +
          objSearch.nowtime +
          "&taskmoney=" +
          objSearch.taskmoney +
          "&tfinsh=" +
          objSearch.tfinsh;
        window.location.replace(gotojumpurl);
      }
      searchCode = res.searchCode;
      searchRandom = res.searchRandom;
      if (res.searchShowCity && res.searchShowCity.indexOf(currentCity)) {
      }
      if (
        res.searchShiledCity &&
        res.searchShiledCity.indexOf(currentCity) != "-1"
      ) {
        searchCode = "";
      }
      // 首先是广告的key值
      tengcnetCode = res.tbsCode;
      sogocode = res.sugoCode;
      tbsSCode = res.tbsSCode;
      baiduCode = res.baiduCode;
      tbsVCode = res.tbsVCode;
      AdShowCity = res.AdShowCity == "null" ? "" : res.AdShowCity;
      tbsRedShowCity =
        res.tbsRedShowCity == "null" || !res.tbsRedShowCity
          ? "1024709a"
          : res.tbsRedShowCity;
      tbsRed = res.tbsRed == "" || res.tbsRed == null ? 0 : res.tbsRed;
      tbsRedShiedCity =
        res.tbsRedShiedCity == null || res.tbsRedShiedCity == ""
          ? 8000
          : res.tbsRedShiedCity;
      hudongurl = res.huCode;
      youCode = res.youCode;
      // 设置搜狗代码长度
      // if (sogocode) {
      //     arrnum = getRandomNumber(0, sogocode.split(' ').length)
      // } else {
      //     arrnum = 0
      // }
      //弹窗广告进行处理
      sigleAd = res.sigleAd;
      // if (res.singleShowCity && res.singleShowCity.indexOf(currentCity) != '-1') {
      //     sigleAd = '1'
      // }
      if (
        res.singleAdShiedCity &&
        res.singleAdShiedCity.indexOf(currentCity) != "-1"
      ) {
        sigleAd = 0;
      }
      popupAdid = res.singleShowCity ? res.singleShowCity : "";
      popupadNum = Number(localStorage.getItem("popnum")) || 0;
      console.log(popupadNum);
      // if (sigleAd !== 1 || popupadNum > res.singleAdTimes || popupadNum === 0) {
      if (sigleAd !== 1 || popupadNum >= res.singleAdTimes) {
        $(".popupClass").hide();
      } else {
        popupadNum++;
        localStorage.setItem("popnum", popupadNum);
        $(".popupClass").show();
      }
      if (
        sigleAd == "1" &&
        res.singleShowCity &&
        res.singleShowCity.length > 20
      ) {
        // $(".popupClass").show()
        addPupuad(res.singleShowCity);
        $("#popupTbccode").show();
      } else {
        $("#popupylhcode").show();
      }
      // 页面加载规则
      // rule:展示的规则
      // mainloop：展示是否循环
      // independentrule：独立展示的城市独立展示的规则
      // independentcity：独立展示的城市
      // independentloop：独立展示的城市是否循环
      let rule = res.adList;
      let mainloop = res.adCyclic;
      let independentrule = res.adCityList;
      let independentcity = res.adCity;
      let independentloop = res.adCityCyclic;
      // independentrule = "ct cs cst cdt"
      if (
        independentcity != "" &&
        independentcity != null &&
        independentcity.indexOf(currentCity) != "-1"
      ) {
        let rulelist1 = independentrule.split(" ");
        let newadRule1 = [];
        rulelist1.forEach((item) => {
          if (item != "") {
            newadRule1.push(item);
          }
        });
        ruleshow = newadRule1;
        // 如果不循环
        if (independentloop == "0") {
        }
      } else {
        // 普通的规则
        let rulelist = rule.split(" ");
        let newadRule = [];
        rulelist.forEach((item) => {
          if (item != "") {
            newadRule.push(item);
          }
        });
        // 去除空格之后的广告加载逻辑 newadRule
        ruleshow = newadRule;
        // 如果不循环
        if (mainloop == "0") {
        }
      }

      // if (ruleshow.indexOf('y') != '-1' && ruleshow.indexOf('y1') != '-1') {
      //     if (getRandomNumber(0, 1) == '1') {
      //         let ruley = ruleshow.indexOf('y')
      //         let ruley1 = ruleshow.indexOf('y1')
      //         ruleshow[ruley] = 'y1'
      //         ruleshow[ruley1] = 'y'
      //     }
      // }
      // if (ruleshow.indexOf('y') != '-1' && ruleshow.indexOf('y1') != '-1' && ruleshow.indexOf('y2') != '-1') {
      //     console.log(String(getRandomNumber(0, 2)))
      //     let ruley = ruleshow.indexOf('y')
      //     let ruley1 = ruleshow.indexOf('y1')
      //     let ruley2 = ruleshow.indexOf('y2')
      //     switch (String(getRandomNumber(0, 2))) {
      //         case '0':
      //             ruleshow[ruley] = 'y1'
      //             ruleshow[ruley1] = 'y2'
      //             ruleshow[ruley2] = 'y'
      //             break;
      //         case '1':
      //             ruleshow[ruley] = 'y2'
      //             ruleshow[ruley1] = 'y'
      //             ruleshow[ruley2] = 'y1'
      //             break;
      //         default:
      //             ruleshow[ruley] = 'y'
      //             ruleshow[ruley1] = 'y1'
      //             ruleshow[ruley2] = 'y2'
      //     }
      // }
      // if (ruleshow.indexOf('y') != '-1' && ruleshow.indexOf('y1') != '-1' && ruleshow.indexOf('y2') != '-1' && ruleshow.indexOf('y3') != '-1') {
      //     let ruley = ruleshow.indexOf('y')
      //     let ruley1 = ruleshow.indexOf('y1')
      //     let ruley2 = ruleshow.indexOf('y2')
      //     let ruley3 = ruleshow.indexOf('y3')
      //     switch (String(getRandomNumber(0, 3))) {
      //         case '0':
      //             ruleshow[ruley] = 'y1'
      //             ruleshow[ruley1] = 'y2'
      //             ruleshow[ruley2] = 'y3'
      //             ruleshow[ruley3] = 'y'
      //             break;
      //         case '1':
      //             ruleshow[ruley] = 'y2'
      //             ruleshow[ruley1] = 'y'
      //             ruleshow[ruley2] = 'y'
      //             ruleshow[ruley3] = 'y1'
      //             break;
      //         case '2':
      //             ruleshow[ruley] = 'y3'
      //             ruleshow[ruley1] = 'y2'
      //             ruleshow[ruley2] = 'y1'
      //             ruleshow[ruley3] = 'y'
      //             break;
      //         default:
      //             ruleshow[ruley] = 'y'
      //             ruleshow[ruley1] = 'y1'
      //             ruleshow[ruley2] = 'y2'
      //             ruleshow[ruley3] = 'y3'
      //     }
      // }

      let num = 0;
      let listru = [
        "ca",
        "ct",
        "cs",
        "cd",
        "bs",
        "csa",
        "cda",
        "bsa",
        "cst",
        "cdt",
        "bst",
      ];
      ruleshow.forEach((item) => {
        // if (item == 'ca' || item == 'ct' || item == 'cs' || item == 'cd' || item == 'bs' || item=='csa'||) {
        //     num++
        // }
        if (listru.indexOf(item) != "-1") {
          num++;
        }
      });

      // 对ruleshow进行处理

      // for (let i = 1; i < (parseInt(12 / num)); i++) {
      //     ruleshow = ruleshow.concat(ruleee)
      // }
      if (
        independentcity != "" &&
        independentcity != null &&
        independentcity.indexOf(currentCity) != "-1"
      ) {
        if (independentloop == "0") {
          xianlajiazai = "0";
          ruleshow = independentrule.split(" ");
        }
      } else {
        if (mainloop == "0") {
          xianlajiazai = "0";
          ruleshow = rule.split(" ");
        }
      }
      console.log("处理中");
      // ruleshow = ["cs", "cs", "#", "ct", "ct"]
      // 从第六个开始循环
      let xunnum = ruleshow.indexOf("#");

      if (xunnum != "-1") {
        // 需要循环的
        wodesear = ruleshow.slice(0, xunnum);
        ruleshow = ruleshow.slice(xunnum + 1);
        console.log(wodesear);

        getListcs("0", pageSize, wodesear);
        // getList('0', pageSize, wodesear)
        let rr = [];
        numrr = 0;
        ruleshow.forEach((item) => {
          if (item == "ca" || item == "ct" || item == "cs" || item == "cd") {
            numrr++;
          }
          if (numrr < 13) {
            rr.push(item);
          }
        });
        ruleshow = rr;
        let ruleee = ruleshow;
        for (let i = 1; i < parseInt(12 / num); i++) {
          ruleshow = ruleshow.concat(ruleee);
        }
      } else {
        let rr = [];
        numrr = 0;
        ruleshow.forEach((item) => {
          if (item == "ca" || item == "ct" || item == "cs" || item == "cd") {
            numrr++;
          }
          if (numrr < 13) {
            rr.push(item);
          }
        });
        ruleshow = rr;

        let ruleee = ruleshow;
        // for (let i = 1; i < (parseInt(12 / num)); i++) {
        //     ruleshow = ruleshow.concat(ruleee)
        // }
        if (xianlajiazai != "0") {
          for (let i = 1; i < parseInt(12 / num); i++) {
            ruleshow = ruleshow.concat(ruleee);
          }
        }

        getList("0", pageSize, ruleshow);
      }
      console.log(ruleshow);

      // if (sigleAd == '1') {
      //     setTimeout(() => {
      //         getList('0', pageSize)
      //     }, 500);
      // } else {
      //     getList('0', pageSize)
      // }

      // 底部的黑条条是否展示
      let dibubottom = res.bottom;
      if (
        res.bottomShowCity &&
        res.bottomShowCity.indexOf(currentCity) != "-1"
      ) {
        dibubottom = "1";
      }
      if (
        res.bottomShiedCity &&
        res.bottomShiedCity.indexOf(currentCity) != "-1"
      ) {
        dibubottom = "0";
      }
      if (dibubottom == "0") {
        $(".dibuQUyu").hide();
        nodiaoyong = "1";
      } else {
        $(".dibuQUyu").show();
      }
      //是否可以跳转详情页
      allowJumpDetail = res.click;
      let allowJumpDetailRandom = getRandomNumber(1, 100);
      if (res.cilckShowCity && res.cilckShowCity.indexOf(currentCity) != "-1") {
        if (allowJumpDetailRandom <= res.clickRandom) {
          allowJumpDetail = "1";
        }
      }
      if (
        res.cilckShiledCity &&
        res.cilckShiledCity.indexOf(currentCity) != "-1"
      ) {
        allowJumpDetail = "0";
      }
      // 是否展示右下角的悬浮配置
      floatingyou = res.floating;
      floatingyouUrl = res.floatingUrl;
      if (
        res.floatingShiedCity &&
        res.floatingShiedCity.indexOf(currentCity) != "-1"
      ) {
        floatingyou = "1";
      }
      if (
        res.floatingShiedCity &&
        res.floatingShiedCity.indexOf(currentCity) != "-1"
      ) {
        floatingyou = "0";
      }
      if (floatingyou == "1") {
        $(".nijiushigeimg").show();
        $(".nijiushigeimg").append(
          '<img src="https://static.zixunkankan.xyz/512%E6%8F%90%E7%8E%B0%E6%82%AC%E6%B5%AE.png?x-oss-process=image/resize,w_240,h_200" data-src="https://static.zixunkankan.xyz/512%E6%8F%90%E7%8E%B0%E6%82%AC%E6%B5%AE.png?x-oss-process=image/resize,w_240,h_200">'
        );
      }
      // 主题色
      $(".box-item .active div").css("background-color", res.bodyColor);
      bodyColor = res.bodyColor;
      // 是否展示头部导航栏
      isShowHead = res.isShowHead;
      // 要展示的目录
      if (res.contentType) {
        // if (isShowHead == '1' && res.contentType != null) {
        if (isShowHead == "1") {
          // let indexjqList =
          // console.log($(".item-group")    )
          const findIndex = Array.from($(".item-group")).findIndex(
            (item) => res.contentType.indexOf(item.innerText) != "-1"
          );
          $(".item-group").each((index, ele) => {
            // console.log(index)
            // console.log(ele)
            if (res.contentType.indexOf(ele.innerText) == "-1") {
              $(ele).remove();
            } else {
            }
            if (index == findIndex) {
              console.log();
              tid = $(ele).attr("data-type");
              $(ele).addClass("active");
              $(".box-item .active div").css("background-color", res.bodyColor);
            }
          });
        }
      }
      if (isShowHead == "0") {
        $(".box").hide();
        $(".articleList").css("margin-top", "0");
      }
      // 添加友盟统计
      if (res.youmengCode) {
        setTimeout(() => {
          let myIp = currentIp;
          console.log(myIp);
          if (myIp) {
            myIp = parseInt(myIp.split(".")[3] / 2.55);
          }
          let rrr = res.youmengCode.split("src=")[1];
          if (myIp <= res.youmengRate) {
            rrr = rrr.substring(1, rrr.length - 11);
          } else {
            rrr = rrr.substring(1, rrr.length - 12);
          }
          var sc1 = document.createElement("script");
          sc1.type = "text/javascript";
          sc1.setAttribute("src", rrr);
          document.getElementById("youmengCode").appendChild(sc1);
        }, 500);
      }
      if (
        res.newinfoYoumengCode &&
        res.newinfoYoumengCode.indexOf("baidu") !== -1
      ) {
        var _hmt = _hmt || [];
        (function () {
          var hm = document.createElement("script");
          hm.src = res.newinfoYoumengCode;
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(hm, s);
        })();
      }

      // 设置页面标题
      document.title = res.titleName;
      // 设置文章样式
      contentTitleWeight = res.contentTitleWeight
        ? res.contentTitleWeight
        : "500";
      // 倒计时区域
      let wwodebottomTime =
        localStorage.getItem(
          "jishunum" + objSearch.pid + objSearch.taskid + objSearch.hid
        ) || "";
      let homeDatechuo =
        localStorage.getItem(
          "homeDatechuo" + objSearch.pid + objSearch.taskid + objSearch.hid
        ) || "";
      bottomTime = wwodebottomTime ? wwodebottomTime : res.bottomTime;
      if (homeDatechuo) {
        let ooonowtime = +new Date();
        bottomTime =
          bottomTime - parseInt((ooonowtime - homeDatechuo) / 1000) >= 0
            ? bottomTime - parseInt((ooonowtime - homeDatechuo) / 1000)
            : 0;
        // bottomTime = bottomTime - Math.floor((ooonowtime - homeDatechuo) / 1000) >= 0 ? bottomTime - Math.floor((ooonowtime - homeDatechuo) / 1000) : 0
      }
      if (bottomTime == "0") {
        localStorage.setItem(
          "jishiover" + objSearch.pid + objSearch.taskid + objSearch.hid,
          "1"
        );
        let dianjiover =
          localStorage.getItem(
            "dianjiover" + objSearch.pid + objSearch.taskid + objSearch.hid
          ) || 0;
        if (dianjiover == "1") {
          overRenwu();
        }
      }
      sumclicknum = res.bottomFre;
      if (Number(currentclicknum) >= Number(sumclicknum)) {
        currentclicknum = sumclicknum;
        localStorage.setItem(
          "dianjiover" + objSearch.pid + objSearch.taskid + objSearch.hid,
          "1"
        );
        let jishiover =
          localStorage.getItem(
            "jishiover" + objSearch.pid + objSearch.taskid + objSearch.hid
          ) || 0;
        if (jishiover == "1") {
          overRenwu();
        }
      }
      let textweban = `点击任意${currentclicknum}/${sumclicknum}篇内容${bottomContent}并阅读${bottomTime}s即可获取奖励`;
      if (nodiaoyong == "1") {
        textweban = renwuOverenan;
      }
      $(".dibuQUyu").text(textweban);
      localStorage.removeItem(
        "jishunum" + objSearch.pid + objSearch.taskid + objSearch.hid
      );
      localStorage.removeItem(
        "homeDatechuo" + objSearch.pid + objSearch.taskid + objSearch.hid
      );
      // 获取列表
      // 返回功能模块
      let backHistory = res.backHistory;
      backshowUrl = res.backUrl;
      if (
        res.backShowCity &&
        res.backShowCity.indexOf(this.currentCity) != "-1"
      ) {
        backHistory = "1";
      }
      if (
        res.backShiedCity &&
        res.backShiedCity.indexOf(this.currentCity) != "-1"
      ) {
        backHistory = "0";
      }
      if (backHistory == "1") {
        // 第一种返回
        if (window.history && window.history.pushState) {
          history.pushState(null, null, document.URL);
          window.addEventListener(
            "popstate",
            function () {
              // window.location.replace(backshowUrl)
              // window.history.pushState('forward', null, '#');
              // window.history.forward(1);
              // alert(backshowUrl)
              window.location.href = backshowUrl;
              // window.location.replace(backshowUrl)
            },
            false
          );
        }
        // 第二种返回
        // pushHistory();

        // window.addEventListener("popstate", function(e) {
        //     // window.location.replace(backshowUrl)
        //     window.location = backshowUrl
        // }, false);

        // function pushHistory() {
        //     var state = {
        //         title: "title",
        //         url: "#"
        //     };
        //     window.history.pushState(state, "title", "#");
        // }
      }
    },
  });
}

/*
下面的全是绑定的点击事件
*/

// 给wenzhangchlick添加点击事件
$(document).on("click", ".wenzhangchlick", function () {
  if (allowJumpDetail == "0") {
    return;
  }
  if (objSearch.hid === "470" || objSearch.hid === "468") {
    window.location.reload();
    return;
  }
  clicknumjiajia();
  let tid = $(this).attr("tid");
  let protocol = window.location.protocol;
  let hostname = window.location.hostname;
  let hostport = window.location.port;
  // hostname = "kuwen10005.xyz"
  console.log(protocol);
  console.log(hostname);
  console.log(hostport);
  pageSize++;
  if (searchCode != "" && searchCode != null) {
    // alert(searchCode)
    let oderandonNumber =
      localStorage.getItem(
        "searchrande" + objSearch.pid + objSearch.taskid + objSearch.hid
      ) || "";
    if (oderandonNumber == "") {
      oderandonNumber = getRandomNumber(0, 100);
      localStorage.setItem(
        "searchrande" + objSearch.pid + objSearch.taskid + objSearch.hid,
        oderandonNumber
      );
    }
    // alert(Number(searchRandom) >= Number(oderandonNumber))
    if (Number(searchRandom) >= Number(oderandonNumber)) {
      if (searchCode.indexOf("baidu+") != "-1") {
        let juri = searchCode.substring(6);
        if (juri.indexOf("http") != "-1") {
          window.location.href = juri;
        } else {
          window.location.href =
            "https://cpu.baidu.com/1022/a462de16/i?pu=1&promotion_media_channel=72317";
          return;
        }
      } else {
        let jus = searchCode.substring(7);
        // getSearchvalue(jus)
      }

      // window.location.href = ''
    }
  }
  let basestr =
    tid +
    "&page=" +
    pageSize +
    "&hid=" +
    objSearch.hid +
    "&userid=" +
    objSearch.userid +
    "&pid=" +
    objSearch.pid +
    "&taskid=" +
    objSearch.taskid +
    "&imei=" +
    objSearch.imei +
    "&sign=" +
    objSearch.sign +
    "&meid=" +
    objSearch.meid +
    "&oaid=" +
    objSearch.oaid +
    "&androidid=" +
    objSearch.androidid +
    "&idfa=" +
    objSearch.idfa +
    "&nowtime=" +
    objSearch.nowtime +
    "&nowtimenum=" +
    bottomTime +
    "&detailnum=1&tasktype=" +
    objSearch.tasktype +
    "&curr=" +
    currentCity +
    "&md=" +
    objSearch.md +
    "&ext=" +
    objSearch.ext;
  basestr = new Base64().encode(basestr);
  console.log(basestr);
  let jumpppuu =
    protocol + "//" + hostname + ":" + hostport + jumppath + basestr;
  // tid +
  // "&page=" +
  // pageSize +
  // "&hid=" +
  // objSearch.hid +
  // "&userid=" +
  // objSearch.userid +
  // "&pid=" +
  // objSearch.pid +
  // "&taskid=" +
  // objSearch.taskid +
  // "&imei=" +
  // objSearch.imei +
  // "&sign=" +
  // objSearch.sign +
  // "&meid=" +
  // objSearch.meid +
  // "&oaid=" +
  // objSearch.oaid +
  // "&androidid=" +
  // objSearch.androidid +
  // "&idfa=" +
  // objSearch.idfa +
  // "&nowtime=" +
  // objSearch.nowtime +
  // "&nowtimenum=" +
  // bottomTime +
  // "&detailnum=1&tasktype=" + objSearch.tasktype + "&ext=" + objSearch.ext;
  console.log(jumpppuu);
  window.location.href = jumpppuu;
});
// 头部的点击
$(document).on("click", ".clickimg", function () {
  if (allowJumpDetail == "0") {
    return;
  }
  if (stabilization_report) {
    return;
  }
  stabilization_report = true;
  stabilizationTimer_report = setTimeout(() => {
    clearTimeout(stabilizationTimer_report);
    stabilization_report = false;
  }, 4000);
  clicknumjiajia();
  let tt = "";
  let ttmsg = "";
  let seaechid = "";
  if ($(this).attr("myindex")) {
    // 点击的是图片
    tt = $(this).parents(".ingBox").prev().attr("data");
    ttmsg = tt.split(",")[$(this).attr("myindex")];
    seaechid = $(this).parents(".ingBox").prev().attr("title-id").split(",")[
      $(this).attr("myindex")
    ];
  } else {
    // 点击的是标题
    let randnum = getRandomNumber(0, 2);
    tt = $(this).attr("data");
    ttmsg = tt.split(",")[randnum];
    seaechid = $(this).attr("title-id").split(",")[randnum];
  }
  if (!ttmsg) {
    // reportsearch(objSearch.hid, "空白1")
    return;
  }
  // reportsearch(objSearch.hid, `${ttmsg}-${seaechid}`)
  // report_new(`${ttmsg}-${seaechid}`)
  pageSize++;
  search_adline(
    searchCode,
    ttmsg,
    $(this).attr("type"),
    objSearch,
    seaechid,
    currentIp
  );
  /*
console.log(111)
let protocol = window.location.protocol;
let hostname = window.location.hostname;

// return

if (searchCode != '' && searchCode != null) {
// alert(searchCode)
let oderandonNumber = localStorage.getItem('searchrande' + objSearch.pid + objSearch.taskid + objSearch.hid) || ''
if (oderandonNumber == '') {
oderandonNumber = getRandomNumber(0, 100)
localStorage.setItem('searchrande' + objSearch.pid + objSearch.taskid + objSearch.hid, oderandonNumber)
}
// alert(Number(searchRandom) >= Number(oderandonNumber))
if (Number(searchRandom) >= Number(oderandonNumber)) {

if (searchCode.indexOf('baidu+') != '-1') {
let juri = searchCode.substring(6)
if (juri.indexOf('http') != '-1') {
window.location.href = juri
} else {
window.location.href = 'https://cpu.baidu.com/1022/a462de16/i?pu=1&promotion_media_channel=72317'
}
} else {
let jus = searchCode.substring(7)
let umjumpSearch = "https://yz.m.sm.cn/s?from=" + jus + "&q=" + ttmsg;
const adtype = ['cd', 'cda', 'cdt']
// if ($(this).attr('type') == 'cd') {
if (adtype.indexOf($(this).attr('type')) != '-1') {
umjumpSearch = "https://search.iscrv.com/api/v1/search/result_page?cp_id=s0001&word=" + ttmsg
}
const bstype = ['bs', 'bsa', 'bst']
if (bstype.indexOf($(this).attr('type')) != '-1') {
// umjumpSearch = "https://m.baidu.com/from=1024709a/s?word=" + ttmsg
umjumpSearch = `https://m.baidu.com/from=${tbsRedShowCity}/s?word=${ttmsg}`
// umjumpSearch = "https://m.baidu.com/from=1024709b/s?word=" + ttmsg
}

reportsearch(objSearch.hid, ttmsg)
if (objSearch.pid == '' || objSearch.pid == '70001') {
window.location.href = umjumpSearch
return
}

let jumppath1 = jumppath.replace('detail', 'detailsear')
let jumpppuu =
protocol +
"//" +
hostname + jumppath1 +
// "/static/web/det/index.html?id=" +
// "/static/web/homejs/detail.html?id=" +
// "/static/web/test/homejs/detail.html?id=" +
tid +
"&page=" +
pageSize +
"&hid=" +
objSearch.hid +
"&userid=" +
objSearch.userid +
"&pid=" +
objSearch.pid +
"&taskid=" +
objSearch.taskid +
"&imei=" +
objSearch.imei +
"&sign=" +
objSearch.sign +
"&meid=" +
objSearch.meid +
"&oaid=" +
objSearch.oaid +
"&androidid=" +
objSearch.androidid +
"&idfa=" +
objSearch.idfa +
"&nowtime=" +
objSearch.nowtime +
"&nowtimenum=" +
bottomTime +
"&detailnum=19999&tasktype=" + objSearch.tasktype + "&ext=" + objSearch.ext + "&urlsear=" + umjumpSearch;
console.log(jumpppuu)

localStorage.removeItem("detailDatechuo19999" + objSearch.pid + objSearch.taskid + objSearch.hid)
localStorage.removeItem("jishunumDetail19999" + objSearch.pid + objSearch.taskid + objSearch.hid)
window.location.href = jumpppuu

}
}
}
*/
});

function reportsearch(pid, word) {
  try {
    word = word.replace(/[ ]/g, "").replace(/[\r\n]/g, "");
  } catch (error) {}
  if (!word) {
    return;
  }
  $.ajax({
    url: "https://zixunkankan.xyz/new/searchStatic",
    data: {
      pid: pid,
      word: word,
      num: 0,
    },
    async: false,
    success: function (res) {},
  });
}

// 头部菜单的
$(document).on("click", ".item-group", function () {
  // let tid = $(this).attr("data-type")
  tid = $(this).attr("data-type");
  console.log(tid);
  pageSize = 1;
  // $("#snatuBox").html()
  document.getElementById("snatuBox").innerHTML = "";
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  if (wodesear.length != 0) {
    getListcs(tid, pageSize, wodesear);
  } else {
    getList(tid, pageSize);
  }
});
// 互动的点击事件
$(document).on("click", ".shangTiophudong", function () {
  clicknumjiajia();
  window.location.href = hudongurl;
});
// 关闭弹窗的事件
$(".openxxxpopup").click(function () {
  $(".popupClass").hide();
});
$(".wodexxx").click(function () {
  $(".popupClass").hide();
});
// 右下角悬浮船的点击事件
$(".nijiushigeimg").click(function () {
  window.location.href = `${floatingyouUrl}?tbscode=${tengcnetCode}`;
});
// 清除缓存
$(".dibuQUyu").click(function () {
  localStorage.clear();
});
// 直投广告的点击事件
$(document).on("click", ".zhitouclick", function () {
  console.log($(this).attr("zhitouid"));
  console.log($(this).attr("zhitouTypecode"));
  clicknumjiajia();
  $.ajax({
    url: "https://www.zixunkankan.xyz/api/new/zclick",
    method: "GET",
    data: {
      id: $(this).attr("zhitouid"),
      typecode: $(this).attr("zhitouTypecode"),
    },
  });

  setTimeout(() => {
    window.location.href = $(this).attr("jumpUrl");
  }, 200);
});
document
  .querySelector(".popupClass")
  .addEventListener("touchmove", function (e) {
    e.preventDefault();
  });
// 滑动检测
document
  .querySelector(".tanchuanzhezhapo")
  .addEventListener("touchstart", touchstartClick);
document
  .querySelector(".tanchuanzhezhapo")
  .addEventListener("touchend", touchstartSanClick);
// 广告上面的触摸层时间
// setTimeout(() => {
//     // console.log(document.querySelector("#snatuBox .zhezhaopopuclass"))
//     document.querySelector("#snatuBox .zhezhaopopuclass").addEventListener("touchstart", touchstartClick)
//     document.querySelector("#snatuBox .zhezhaopopuclass").addEventListener("touchend", touchstartSanClick)

// }, 1000);
// $("#snatuBox").on("touchstart",".zhezhaopopuclass",function(e){
//     console.log(e)
// })
// $("#snatuBox").on("touchend",".zhezhaopopuclass",touchstartSanClick)
let startX;
let startY;
document
  .querySelector("#snatuBox")
  .addEventListener("touchstart", function (e) {
    // console.log(e.target)
    if (e.target.getAttribute("class") == "zhezhaopopuclass") {
      startX = e.changedTouches[0].pageX;
      startY = e.changedTouches[0].pageY;
    }
  });
document.querySelector("#snatuBox").addEventListener("touchend", function (e) {
  // console.log()
  if (e.target.getAttribute("class") == "zhezhaopopuclass") {
    let moveEndX = e.changedTouches[0].pageX;
    let moveEndY = e.changedTouches[0].pageY;
    let X = moveEndX - startX;
    let Y = moveEndY - startY;
    if (X > 0) {
    } else if (X < 0) {
    } else if (Y > 0) {
    } else if (Y < 0) {
    } else {
      // console.log(111111);
      $(".zhezhaopopuclass").hide();
      clicknumjiajia();
    }
  }
});

function touchstartClick(e) {
  startX = e.changedTouches[0].pageX;
  startY = e.changedTouches[0].pageY;
}

function touchstartSanClick(e) {
  // this.clicknumJia();
  let moveEndX = e.changedTouches[0].pageX;
  let moveEndY = e.changedTouches[0].pageY;
  let X = moveEndX - startX;
  let Y = moveEndY - startY;
  if (X > 0) {
  } else if (X < 0) {
  } else if (Y > 0) {
  } else if (Y < 0) {
  } else {
    // console.log(111111);
    $(".tanchuanzhezhapo").hide();
    // this.clicknumJia();
  }
}
// 头部拼接广告的一些东西
try {
  // 页面全部的高度
  let visibleArea = document.documentElement.clientHeight;
  // 页面高度跟原生获取高度的比例
  let visibleAreabili = 0;
  // 进入页面就会执行的方法
  function getMeasured(width, height) {
    visibleAreabili = visibleArea / height;
  }

  function getClickLoact(DownX, DownY) {
    if (
      visibleArea - document.documentElement.clientHeight != "0" &&
      Number(DownY * visibleAreabili) < 140
    ) {
      clicknumjiajia();
    }
  }
} catch (error) {
  // alert(error)
}
// 触底监听
function getDocumentTop() {
  var scrollTop = 0,
    bodyScrollTop = 0,
    documentScrollTop = 0;
  if (document.body) {
    bodyScrollTop = document.body.scrollTop;
  }
  if (document.documentElement) {
    documentScrollTop = document.documentElement.scrollTop;
  }
  scrollTop =
    bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
  return scrollTop;
}

function getWindowHeight() {
  var windowHeight = 0;
  if (document.compatMode == "CSS1Compat") {
    windowHeight = document.documentElement.clientHeight;
  } else {
    windowHeight = document.body.clientHeight;
  }
  return windowHeight;
}

function getScrollHeight() {
  var scrollHeight = 0,
    bodyScrollHeight = 0,
    documentScrollHeight = 0;
  if (document.body) {
    bodyScrollHeight = document.body.scrollHeight;
  }
  if (document.documentElement) {
    documentScrollHeight = document.documentElement.scrollHeight;
  }
  scrollHeight =
    bodyScrollHeight - documentScrollHeight > 0
      ? bodyScrollHeight
      : documentScrollHeight;
  return scrollHeight;
}
window.onscroll = function () {
  lazyLoading();
  getBindMove();
  scriptLoading();
  if (getScrollHeight() <= getWindowHeight() + getDocumentTop() + 10) {
    if (stabilization) {
      return;
    }
    stabilization = true;
    stabilizationTimer = setTimeout(() => {
      clearTimeout(stabilizationTimer);
      stabilization = false;
    }, 200);
    console.log("bottom");
    if (xianlajiazai == "0") {
      return;
    }
    pageSize++;
    getList(tid, pageSize);
  }
};
// 第五部分
var scroll = document.querySelector(".wodededede");
var outerScroller = document.querySelector(".box");
var touchStart = 0;
var touchDis = 0;
outerScroller.addEventListener(
  "touchstart",
  function (event) {
    var touch = event.targetTouches[0];
    // 把元素放在手指所在的位置
    touchStart = touch.pageY;
    console.log(touchStart);
  },
  false
);
outerScroller.addEventListener(
  "touchmove",
  function (event) {
    var touch = event.targetTouches[0];
    console.log(touch.pageY + "px");
    scroll.style.top = scroll.offsetTop + touch.pageY - touchStart + "px";
    console.log(scroll.style.top);
    touchStart = touch.pageY;
    touchDis = touch.pageY - touchStart;
  },
  false
);
outerScroller.addEventListener(
  "touchend",
  function (event) {
    touchStart = 0;
    var top = scroll.offsetTop;
    console.log(top);
    if (top > 70) refresh();
    if (top > 0) {
      var time = setInterval(function () {
        scroll.style.top = scroll.offsetTop - 2 + "px";
        if (scroll.offsetTop <= 0) clearInterval(time);
      }, 1);
    }
  },
  false
);

function refresh() {
  // 刷新当前页面
  // console.log(111)
  window.location.reload();
}
// 图片懒加载
// 判断img是否出现浏览器视野中
function checkShow(img) {
  // let scrollTop = document.documentElement.scrollTop; // 页面向上滚动的高度
  // let windowHeight = window.innerHeight; //浏览器自身高度
  // let offsetTop = img.offsetTop; //目标标签相对于document的高度
  // console.log(offsetTop +"---"+windowHeight +'----页面滚动高度------'+scrollTop)
  // return (offsetTop > scrollTop && offsetTop < (windowHeight + scrollTop));

  var scrollTop = $(window).scrollTop(); //即页面向上滚动的距离
  var windowHeight = $(window).height(); // 浏览器自身的高度
  var offsetTop = img.offset().top; //目标标签img相对于document顶部的位置

  if (offsetTop < scrollTop + windowHeight && offsetTop > scrollTop) {
    //在2个临界状态之间的就为出现在视野中的
    return true;
  }
  return false;
}
// 判断是否已经加载过
function isLoaded(img) {
  return img.getAttribute("src") === img.getAttribute("data-src");
}

// 加载图片
function loadImg(img) {
  img.setAttribute("src", img.getAttribute("data-src"));
}
// window绑定滚动事件
// window.addEventListener('scroll', function () {
//     lazyLoading()
//     getBindMove()
//     scriptLoading()
// });
// 第一次进页面加载处于视野中的图片
function lazyLoading() {
  Array.prototype.slice
    .apply(document.getElementsByTagName("img"))
    .forEach((img) => {
      // console.log(img)
      // console.log(img.getAttribute('class'))
      // if(img.getAttribute('class') === 'img'||img.getAttribute('class') === 'ad-png' ){
      //     return
      // }
      // ins
      // console.log(111111111)
      // let img = li.getElementsByTagName('img')[0];
      // 判断当前img是否出现在了视野中
      // 判断当前img是否被加载过了
      if (checkShow($(img)) && !isLoaded(img) && !imgxiaomi(img)) {
        loadImg(img);
      }
    });
}

function scriptLoading() {
  Array.prototype.slice
    .apply(document.getElementsByTagName("script"))
    .forEach((scriptDom) => {
      let scrollTop = $(window).scrollTop();
      // 浏览器的窗口高度
      let chazhiHeight = $(window).height();
      if (
        scriptDom.getAttribute("adkey") &&
        scriptDom.getAttribute("src") != scriptDom.getAttribute("data-src")
      ) {
        // console.log(checkShow($(scriptDom)))
        // console.log($(scriptDom).parent("div").attr("ishow"))
        if ($(scriptDom).parent("div").attr("ishow") == "1") {
          scriptDom.setAttribute("src", scriptDom.getAttribute("data-src"));
        }
      }
    });
}
if (listtimekeeper) {
  startNumjianjian();
}

// 开始定时器
function startNumjianjian() {
  if (nodiaoyong == "1") {
    return;
  }
  let wodetimer = setInterval(() => {
    bottomTime--;
    if (bottomTime <= 0) {
      clearInterval(wodetimer);
      bottomTime = 0;
      localStorage.setItem(
        "jishiover" + objSearch.pid + objSearch.taskid + objSearch.hid,
        "1"
      );
      let dianjiover =
        localStorage.getItem(
          "dianjiover" + objSearch.pid + objSearch.taskid + objSearch.hid
        ) || 0;
      if (dianjiover == "1") {
        overRenwu();
      }
    }
    let textweban = `点击任意${currentclicknum}/${sumclicknum}篇内容${bottomContent}并阅读${bottomTime}s即可获取奖励`;

    $(".dibuQUyu").text(textweban);
  }, 1000);
}

function clicknumjiajia() {
  // 发送消息到快应用
  try {
    // system.onmessage = function (data) {
    //     console.log('message received: ' + data)
    // }
    system.postMessage("click");
  } catch (error) {}
  if (objSearch.pid != "") {
    // reportsearch(objSearch.pid + '1', '列表页点击')
  }
  if (objSearch.hid == "247") {
    adexposure("click", "1");
    adexposure("show", showNum);
  }
  currentclicknum++;
  if (Number(currentclicknum) >= Number(sumclicknum)) {
    currentclicknum = sumclicknum;
    localStorage.setItem(
      "dianjiover" + objSearch.pid + objSearch.taskid + objSearch.hid,
      "1"
    );
    let jishiover =
      localStorage.getItem(
        "jishiover" + objSearch.pid + objSearch.taskid + objSearch.hid
      ) || 0;
    if (jishiover == "1") {
      overRenwu();
    }
  }
  localStorage.setItem(
    "currentclicknum" + objSearch.pid + objSearch.taskid + objSearch.hid,
    currentclicknum
  );
  // 保存现在的秒数
  localStorage.setItem(
    "jishunum" + objSearch.pid + objSearch.taskid + objSearch.hid,
    bottomTime
  );
  //保存现在的时间戳
  let nowtime = +new Date();
  localStorage.setItem(
    "homeDatechuo" + objSearch.pid + objSearch.taskid + objSearch.hid,
    nowtime
  );
}
// 完成任务接口
function overRenwu() {
  if (nodiaoyong == "1") {
    return;
  }
  if (objSearch.pid == "") {
    return;
  }
  if (objSearch.pid == "10092") {
    mkoverrenwu();
    return;
  }
  if (objSearch.tasktype == "kuwanzhuan") {
    $.ajax({
      url: "https://www.zixunkankan.xyz/kuwan/changeTaskStatue",
      method: "GET",
      data: {
        userid: objSearch.userid,
        pid: objSearch.pid,
        taskid: objSearch.taskid,
        nowtime: new Date().getTime(),
        gtype: "1",
        imei: objSearch.imei,
        sign: objSearch.sign,
        gameid: "24",
        meid: objSearch.meid,
        oaid: objSearch.oaid,
        androidid: objSearch.androidid,
        idfa: objSearch.idfa,
      },
      dataType: "json",
      success: function (res) {
        localStorage.setItem(
          "nodiaoyong" + objSearch.pid + objSearch.taskid + objSearch.hid,
          "1"
        );
        $(".dibuQUyu").text(renwuOverenan);
      },
    });
    return;
  } else {
    $.ajax({
      url: "https://www.zixunkankan.xyz/new/userCompleteNew",
      method: "GET",
      data: {
        userid: objSearch.userid,
        pid: objSearch.pid,
        taskid: objSearch.taskid,
        imei: objSearch.pid == "70001" ? "null" : objSearch.imei,
        nowtime: new Date().getTime(),
        ext: objSearch.ext,
      },
      dataType: "json",
      success: function (res) {
        if (objSearch.pid == "10084") {
          setTimeout(() => {
            localStorage.setItem(
              "nodiaoyong" + objSearch.pid + objSearch.taskid + objSearch.hid,
              "1"
            );
            $(".dibuQUyu").text(renwuOverenan);
          }, 1000);
          return;
        }
        localStorage.setItem(
          "nodiaoyong" + objSearch.pid + objSearch.taskid + objSearch.hid,
          "1"
        );
        $(".dibuQUyu").text(renwuOverenan);
      },
    });
  }
}

function mkoverrenwu() {
  $.ajax({
    url: "https://www.zixunkankan.club/new/userCompleteStatic",
    method: "GET",
    data: {
      userid: objSearch.userid,
      pid: objSearch.pid,
      taskid: objSearch.taskid,
      imei: objSearch.imei,
      nowtime: new Date().getTime(),
    },
    dataType: "json",
    success: function (res) {
      $.ajax({
        url: "https://www.zixunkankan.club/new/userCompleteNewNew",
        method: "GET",
        data: {
          userid: objSearch.userid,
          pid: objSearch.pid,
          taskid: objSearch.taskid,
          imei: objSearch.imei,
          nowtime: new Date().getTime(),
        },
        dataType: "json",
        success: function (res) {
          nodiaoyong = "1";
          localStorage.setItem(
            "nodiaoyong" + objSearch.pid + objSearch.taskid + objSearch.hid,
            "1"
          );
          $(".dibuQUyu").text(renwuOverenan);
        },
      });
    },
  });
}
// try {
//     // 页面全部的高度
//     let visibleArea = document.documentElement.clientHeight;
//     // 页面高度跟原生获取高度的比例
//     let visibleAreabili = 0;
//     // 进入页面就会执行的方法
//     window.getMeasured = function(width, height) {
//         visibleAreabili = visibleArea / height;
//     };

//     window.getClickLoact = function(DownX, DownY) {
//         if (
//             visibleArea - document.documentElement.clientHeight != "0" &&
//             Number(DownY * visibleAreabili) < 100
//         ) {
//             clicknumjiajia()
//         }
//     };
// } catch (error) {
//     // alert(error);
// }
// 检测页面的展示
// let allowreload = false
// setTimeout(() => {
//     allowreload = true
// }, 2000);
// }, 1);
// alert(window.location.pathname)
document.addEventListener(
  "visibilitychange",
  () => {
    if (document.hidden) {
    } else {
      // if (allowreload) {
      console.log("reload");
      window.location.reload();
      // }
    }
  },
  false
);
export default {
  id: "1",
};
