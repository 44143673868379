export function reportIp(hid) {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: 'https://www.zixunkankan.xyz/new/user_statistics',
            method: 'GET',
            data: {
                hid
            },
            dataType: "json",
            success: function(res) {
                resolve(res.data)
            },
            fail: function() {
                reject('error')
            }
        })
    })
}